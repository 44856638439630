<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" [routerLink]="'/dashboard'">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>

    <span class="title-text h1">
      {{ref}}
    </span>
  </div>

  <div class="white-box">
    <div class="tHeader">
      <h4> Project Details </h4>
      <div fxFlex class="text-right">
        Rush Project: <b><span *ngIf="rush_project==1">Yes</span><span *ngIf="rush_project==0">No</span></b>
      </div>
    </div>

    <div class="boxBody">

      <div class="boxBodyInn">

        <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-1" fxFlex="20%">
            <p class="dLabel">Source Language</p>
            <p class="dValue">{{source_lang}}</p>
          </div>
          <div class="item item-2" fxFlex="20%">
            <p class="dLabel">Target Language</p>
            <p class="dValue">{{target_lang}}</p>
          </div>
          <div class="item item-3" fxFlex="20%">
            <p class="dLabel">Specialization</p>
            <p class="dValue">{{specialization_name}}</p>
          </div>
          <div class="item item-4" fxFlex="20%">
            <p class="dLabel" *ngIf="duration">Estimated Time</p>
            <p class="dValue" *ngIf="duration">{{duration}}
                <span *ngIf="duration_unit==1">Minutes</span>
                <span *ngIf="duration_unit==2">Hours</span>
                <span *ngIf="duration_unit==3">Days</span>
              </p>
          </div>
          <div class="item item-5" fxFlex="20%">
            <p class="dLabel">Deadline</p>
            <p class="dValue">{{due_date}}</p>
          </div>
        </div>


        <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-1" fxFlex="20%">
            <p class="dLabel">Scheduled Update</p>
            <p class="dValue" *ngIf="update_status_interval">{{update_status_interval}} </p>
          </div>
          <div class="item item-2" fxFlex="20%">
            <p class="dLabel">Volume(Source words)</p>
            <p class="dValue">{{quantity_words}}</p>
          </div>
          <div class="item item-3" fxFlex="20%">
            <p class="dLabel">Target Country(Dilect)</p>
            <p class="dValue"></p>
          </div>
          <div class="item item-4" fxFlex="20%">
            <p class="dLabel">Rate</p>
            <p class="dValue" *ngIf="rate">${{rate}} USD</p>
          </div>
          <div class="item item-5" fxFlex="20%">
            <p class="dLabel">Total Value</p>
            <p class="dValue" *ngIf="amount"><b class="txt-green"> ${{amount}} USD</b></p>
          </div>
        </div>





        <!-- <div class="box-blank">
          <p>File to be Signed: <button mat-flat-button color="accent"> <i
                class="material-icons">
                arrow_downward
              </i></button> </p>

        </div> -->


        <div class="box-blank amber-50" *ngIf="customer_note">
          <p class="bLabel">Project Notes from Customer:</p>

          <p>{{customer_note}}</p>

          <div class="sep-20"></div>


          <p class="bLabel" *ngIf="instruction">Special instructions note:</p>

          <p>{{instruction}}</p>

        </div>

        <div class="box-blank">
          <p class="bLabel">Source/Original Files:</p>
          <p *ngIf="file_name_data.length>0">
              <span fxFlex="70" >
                <button *ngIf="file_id_data.length>0" mat-stroked-button > 
                      <a [href]="file_d" target="_blank" download ><i class="material-icons" >
                   cloud_download
                 </i> Download All </a></button>
              </span>
            </p>
          <p *ngIf="file_name_data.length>0">
            <span *ngFor="let res of file_name_data" >
                <p>{{res.file}}
                <a [href]="res.file_url"  target="_blank" download>
                    <mat-icon>vertical_align_bottom</mat-icon>
                  </a>
                </p>
            </span>
          </p>
         
         
          <p *ngIf="file_name_data.length==0"> No record <a  >
            <mat-icon>vertical_align_bottom</mat-icon>
          </a>
        </p>
     
          <div class="sep-20"></div>
<!-- 
          <p class="bLabel">Reference Files:</p>

          <p><button mat-raised-button color="accent" class="mr-12"><i class="material-icons">
                arrow_downward
              </i></button>
          </p>


          <p class="bLabel">COA Templates:</p>

          <p><button mat-raised-button color="accent" class="mr-12"><i class="material-icons">
                arrow_downward
              </i></button> <button mat-raised-button color="accent" class="mr-12"> <i
                class="material-icons">
                arrow_downward
              </i></button>

            <button mat-raised-button color="accent"> <i class="material-icons">
                cloud_download
              </i></button>

          </p> -->

        </div>


      </div>





    </div>

  </div>

  <div class="white-box" *ngIf="status=='1'">
    <div class="tHeader">
      <h2> Review Project </h2>
    </div>

    <div class="boxBody">
      <form name="Form" [formGroup]="Form">
      <div class="boxBodyInn">

        <h4>Instructions</h4>

        <p>**TRANSLATE HIGHLIGHTED TEXT ONLY** </p>

        <p>PLEASE FOLLOW THE FORMAT OF THE SOURCE DOCUMENTS</p>

        <p>Please include the stamps when translating (Please do not change the name of the file except to add the
          target language) </p>

        <hr />

        <h4> STEP BY STEP INSTRUCTIONS </h4>

        <p>1. Once translation is approved by client, we will need you to print out the documents (Original file,
          Translated file, COA) in nice color printing and on nice paper. </p>

        <p>2. Have the documents (Original, translated file and COA) notarized in NY. </p>

        <p>3. Once everything is done, we will need you to scan the notarization and send them back to us before
          mailing. Once approved, we will be providing the mailing label for this project so you can send out the docs
          via FedEx.</p>


        <hr />

        <h4> TRANSLATOR POLICY </h4>

        <mat-checkbox (change)="check_term_conditions($event)" formControlName="confidential">I understand the documents provided to me and the translations thereof are confidential and I
          confirm I will not disclose these documents to other parties or individuals. </mat-checkbox>
        <mat-checkbox (change)="check_term_conditions($event)" formControlName="payment_source_word">I understand payment is made for source words, not target word count.</mat-checkbox>
        <mat-checkbox (change)="check_term_conditions($event)"  formControlName="discrepancy_word_count">If I notice a discrepancy in the word count, I will notify Project Managers. I understand my
          invoice should contain the word count provided to me and it can only be different if it was agreed to
          beforehand between me and the Project Managers. </mat-checkbox>
        <mat-checkbox (change)="check_term_conditions($event)" formControlName="payment_instructions" >I understand payment is done within 15 days after receipt of the invoice and it is my
          responsibility to submit my invoices as per the <a href="#">payment instructions</a> provided to me.
        </mat-checkbox>
      </div>

      <div class="actWraper">
        <mat-checkbox formControlName="Category" (change)="check_term_conditions($event)">I pledge that I will not use translation (CAT) tools in any project with Day Translations, Inc. or
          Your Spanish Translation, Inc.</mat-checkbox>
        <p fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center"> 
            <button mat-stroked-button color="warn" (click)="Reject()">Reject</button>
          <button mat-raised-button color="accent"    [disabled]="show" (click)="Accept()">I Accept and Agree</button>
        </p>
      </div>
      </form>



    </div>





  </div>


  <div class="white-box">
    <div class="tHeader">
      <!-- <h2> Questions or comment? </h2> -->
      <h2>COMMENTS / COMMUNICATION / SPECIAL INSTRUCTIONS</h2>
    </div>
    <div class="boxBody">

      <div class="boxBodyInn">
        <div class="commentDiv" *ngFor="let res of comment_Response">
          <div class="quesDiv" *ngIf="user_id!=res.from_user_id">
            <p><b>{{res.userdata?.fullname}}</b> 
              <span class="tStamp"> {{res?.userdata?.user_group_id}} </span>
              <span class="tStamp"> {{res?.created_at | date: 'yyyy-MM-dd'}} {{res?.created_at.date | date:"HH:mm"}}</span>
             </p>
            <p>{{res?.message}}</p>
          </div>
          <div class="ansDiv" *ngIf="user_id==res.from_user_id">
            <div class="divinn">
              <p><b>{{res.userdata?.fullname}}</b> 
                <span class="tStamp"> {{res?.userdata?.user_group_id}} </span>
                <span class="tStamp"> {{res?.created_at | date: 'yyyy-MM-dd'}} {{res?.created_at.date | date:"HH:mm"}}</span> </p>
              <p>{{res?.message}}</p>
            </div>
          </div>
        </div>

        <!-- <div class="commentDiv">
            <div class="quesDiv" *ngFor="let res of comment_Response">
              <p><b>{{res.userdata?.fullname}}</b> <span class="tStamp"> {{res?.created_at | date: 'yyyy-MM-dd'}} {{res?.created_at.date | date:"HH:mm"}}</span> </p>
              <p>{{res?.message}}</p>
            </div>
          </div> -->
        <div class="box-blank amber-50">
            <form name="commentForm"  [formGroup]="commentForm">
          <p class="bLabel">We will reply immediately</p>

          <p>We are here 24/7 for any questions you may have</p>

          <p>
            <mat-form-field class="d-full-width">
              <textarea matInput placeholder="Leave a comment" formControlName="message"  ></textarea>
              <mat-hint>Note: if you have questions before accepting, please enter them here and click I have questions
                to not accept for now.</mat-hint>
            </mat-form-field>
          </p>

          <p> <button mat-raised-button color="primary" type="submit" (click)="submitComment()" [disabled]="commentForm.invalid">I have questions</button></p>
        </form>
        </div>

      </div>
    </div>
  </div>





</div>