import { Component, OnInit } from '@angular/core';
export interface Food {
  name: string;
  description: string;
  duedate: string;
  projvalue: string;
}
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  dataSource: Food[] = [
    {name: 'Project Name', description: 'Hindi <> English Translation', duedate:'Tuesday, May 17th 2019', projvalue: '830 Words / $120.00'},
    {name: 'Project Name', description: 'Hindi <> English Translation', duedate:'Tuesday, May 17th 2019', projvalue: '830 Words / $120.00'},
    {name: 'Project Name', description: 'Hindi <> English Translation', duedate:'Tuesday, May 17th 2019', projvalue: '830 Words / $120.00'},
 ];
 displayedColumns: string[] = ['name', 'description', 'duedate', 'projvalue', 'actions'];
  constructor() { }

  ngOnInit() {
  }

}
