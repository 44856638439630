import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule,MatDatepickerModule, MatIconModule,MatSelectModule, MatTableModule, MatProgressBarModule, MatButtonModule, MatRadioModule, MatSliderModule, MatBadgeModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FuseModule } from '@fuse/fuse.module';
import { fuseConfig } from 'app/fuse-config';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ProfileComponent } from './profile.component';
import { SupportComponent } from './support/support.component';
import {MatPaginatorModule} from '@angular/material/paginator';
const routes = [
  {
      path     : 'profile',
      component: ProfileComponent
  },
  {
    path     : 'profile/edit-profile',
    component: EditProfileComponent
},
{
  path     : 'support',
  component: SupportComponent
}
];
@NgModule({
  declarations: [ProfileComponent, EditProfileComponent, SupportComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDatepickerModule,
    TranslateModule.forRoot(),
    MatPaginatorModule,
    // Material moment date module
    MatMomentDateModule,
    // Material
    MatButtonModule,
    MatIconModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    MatTableModule,        // App modules
    LayoutModule,
    MatSelectModule,
    MatProgressBarModule,
    MatRadioModule,
   
    MatSliderModule, RouterModule.forChild(routes),
    MatBadgeModule,MatCheckboxModule,FormsModule,ReactiveFormsModule,MatFormFieldModule,MatInputModule
  ]
})
export class ProfileModule { }