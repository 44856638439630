import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar,} from '@angular/material';
import { DashboardService } from '../../services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'app/main/shared.service';

@Component({
  selector: 'app-freelancer-accept-project',
  templateUrl: './freelancer-accept-project.component.html',
  styleUrls: ['./freelancer-accept-project.component.scss']
})
export class FreelancerAcceptProjectComponent implements OnInit {
  source_lang :string= '';
  target_lang:string = '';
  specialization_name:string = '';
  ref:string='';
  due_date:string='';
  rate:number;
  dataSource_NewRequest:any;
  quantity_words:number;
  duration:number;
  amount:string='';
  duration_unit:string='';
  update_status_interval:string;
  project_language_assignment_id:number;
  constructor( public dialogRef: MatDialogRef<FreelancerAcceptProjectComponent>,
     @Inject(MAT_DIALOG_DATA) public data :any,
      private dashboardService : DashboardService,private toastr: ToastrService,private router: Router) { }

  ngOnInit() {
    
    if(this.data) {
        this.project_language_assignment_id=this.data.id;
        this.rate=this.data.rate;
        this.duration=this.data.duration;
        this.duration_unit=this.data.duration_unit;
        this.quantity_words=this.data.quantity_words;
        this.update_status_interval= this.timeConvert(this.data.update_status_interval) ;
        this.amount=this.data.amount;
        this.data.projectlanguage.forEach(elm => {
          this.source_lang = elm.sourcelanguage.lang_name ;
          this.target_lang=elm.targetlanguage.lang_name;
            this.ref=elm.project.reference;
            this.due_date=elm.project.due_date;
            if(elm.project.specilization){
              this.specialization_name=elm.project.specilization.name;
            }
            
        });
     }
  }

  // Accept(){
  //   let obj:any={
  //     project_language_assignment_id:this.project_language_assignment_id,
  //     status:'2'
  //   }
  //   this.dashboardService.ProjectAssignmentAccept(obj)
  //   .subscribe((response) => {
  //       if(response) {
  //         this.toastr.success("Project Assignment Accept Successfully");
  //         window.location.href = '/dashboard';
  //       }
  //       else{
  //         this.toastr.error("Something went wrong");
  //       }
  //   }, (error) => {
  //       this.toastr.error("Someting went wrong");
  //   });
  // }

     // calulate time from minute

         timeConvert(n) {

          if(n<0){
            n=-1*n;
          }
          var num = n;
          var hours = (num / 60);
          var rhours = Math.floor(hours);
          var days = (hours / 24);
          var rdays = Math.floor(days);
          var minutes = (hours - rhours) * 60;
          var rminutes = Math.round(minutes);
         
          return  rhours + " hour(s) and " + rminutes + " minute(s)";
          }


}
