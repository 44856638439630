<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>

  <div class="recent-inv">


    <div class="rHeader">

      <h3> Recent Invoices</h3>

    </div>

    <div class="boxRecent">
      <div class="boxRecentInn">
        <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="20px" fxLayoutGap.xs="0">
          <div class="bBox" fxFlex="25%">
            
           
            <div [routerLink]="['/invoices/invdetails']" class="bBoxInn bxLink">
              <h4>DayTranslations, Inc</h4>

              <div class="invValWrap">
                <p class="mb-0">TOTAL</p>
                <p class="invVal mt-0">$862.00 USD</p>
              </div>


              <p class="mb-0">Invoice #039</p>
              <p class="mt-0">May 27th, 2019</p>
            </div>
         

          </div>

          <div class="bBox" fxFlex="25%">
            <div class="bBoxInn bxLink" [routerLink]="['/invoices/invdetails']">
              <h4>Project# terw8265</h4>

              <div class="invValWrap">
                <p class="mb-0">TOTAL</p>
                <p class="invVal mt-0">$862.00 USD</p>
              </div>


              <p class="mb-0">Invoice #039</p>
              <p class="mt-0">May 27th, 2019</p>
            </div>
          </div>
          <div class="bBox" fxFlex="25%">
            <div class="bBoxInn bxLink" [routerLink]="['/invoices/invdetails']">
              <h4>DayTranslations, Inc</h4>

              <div class="invValWrap">
                <p class="mb-0">TOTAL</p>
                <p class="invVal mt-0">$862.00 USD</p>
              </div>


              <p class="mb-0">Invoice #039</p>
              <p class="mt-0">May 27th, 2019</p>
            </div>
          </div>
          <div class="bBox" fxFlex="25%">
            <div class="bBoxInn bxLink" [routerLink]="['/invoices/invdetails']">
              <h4>DayTranslations, Inc</h4>

              <div class="invValWrap">
                <p class="mb-0">TOTAL</p>
                <p class="invVal mt-0">$862.00 USD</p>
              </div>


              <p class="mb-0">Invoice #039</p>
              <p class="mt-0">May 27th, 2019</p>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

  <table mat-table [dataSource] = "dataSource" class = "table"> 
    <ng-container matColumnDef = "name">
       <th mat-header-cell *matHeaderCellDef> Name</th>
       <td mat-cell *matCellDef = "let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef = "description">
       <th mat-header-cell *matHeaderCellDef>Description</th>
       <td mat-cell *matCellDef = "let element"> {{element.description}} </td>
    </ng-container>
    <ng-container matColumnDef = "duedate">
       <th mat-header-cell *matHeaderCellDef>duedate</th>
       <td mat-cell *matCellDef = "let element"> {{element.duedate}} </td>
    </ng-container>
    <ng-container matColumnDef = "projvalue">
       <th mat-header-cell *matHeaderCellDef>Status</th>
       <td class="w250" mat-cell *matCellDef = "let element">
            <mat-progress-bar mode="determinate" value="0"></mat-progress-bar> 
            
            <div fxLayout="column">
            <div class="smlText" fxFlexAlign="start">Not Started Yet</div>
            
            <div class="smlText" fxFlexAlign="end">
                  <span class="sText"><i class="material-icons">     access_time    </i> 26 Minutes elasped </span>
               
            </div>
           </div>
      </td>
    </ng-container>
   
    <ng-container matColumnDef = "actions">
       <th mat-header-cell *matHeaderCellDef></th>
   
      <td mat-cell class="actColumn align-right" *matCellDef = "let element"> 
   
 
         <button mat-flat-button class="mr-16 b-green" color="primary">Start</button>
        
   
      </td>
    </ng-container> 
   
    <tr mat-header-row *matHeaderRowDef = "displayedColumns"></tr>
    <tr mat-row *matRowDef = "let row; columns: displayedColumns;"></tr>
   </table>

  <div class="white-box">
    <div class="tHeader">
      <h4> All Invoices </h4>
    </div>

    <div class="boxBody">
      <div class="boxBodyInn">
        <div  [routerLink]="['/invoices/invdetails']" class="container bxLink" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-1" fxFlex="20%">
            <p class="pl10">#DAY001</p>

          </div>
          <div class="item item-2" fxFlex="20%">
            <p>Spanish < English, 450 Words</p> </div> <div class="item item-3" fxFlex="20%">
                <p><b>$132.00 USD</b></p>
          </div>
          <div class="item item-4" fxFlex="20%">
            <p>May 23th 2019, 16:12 EST</p>
          </div>
          <div class="item item-4" fxFlex="20%">
            <p class="txt-green">Paid</p>
          </div>
        </div>


        <div [routerLink]="['/invoices/invdetails']" class="container bxLink" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-1" fxFlex="20%">
            <p class="pl10">#DAY002</p>

          </div>
          <div class="item item-2" fxFlex="20%">
            <p>Spanish < French, 120 Words</p> </div> <div class="item item-3" fxFlex="20%">
                <p><b>$ 18.00 USD</b></p>
          </div>
          <div class="item item-4" fxFlex="20%">
            <p>May 27th 2019, 16:12 EST</p>
          </div>
          <div class="item item-4" fxFlex="20%">
            <p class="txt-green">Paid</p>
          </div>
        </div>




      </div>

    </div>

  </div>


</div>