import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule, MatIconModule, MatTableModule, MatProgressBarModule, MatButtonModule, MatRadioModule, MatSliderModule, MatBadgeModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ProjectsComponent } from './projects.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FuseModule } from '@fuse/fuse.module';
import { fuseConfig } from 'app/fuse-config';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { StatusUpdateComponent } from './status-update/status-update.component';
import { FinishProjectComponent } from './finish-project/finish-project.component';
import { AuthGuard } from 'app/_guards/auth.guard';
import { AllNotificationsComponent } from './all-notifications/all-notifications.component';
const routes = [
  {
      path     : 'projects/update-status/:id',
      component: StatusUpdateComponent,
      canActivate: [AuthGuard] 
  },
  {
    path     : 'projects/finish-project/:id',
    component: FinishProjectComponent,
    canActivate: [AuthGuard] 
},
{
  path        : 'projects', 
  component: ProjectsComponent,
  canActivate: [AuthGuard] 
  
},
{
  path        : 'projects/project-details/:id', 
  component: ProjectDetailsComponent,
  canActivate: [AuthGuard]
},
{
  path     : 'projects/notifications',
  component: AllNotificationsComponent,
  canActivate: [AuthGuard] 
},
];
@NgModule({
  declarations: [ProjectsComponent, ProjectDetailsComponent, StatusUpdateComponent, FinishProjectComponent, AllNotificationsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    // Material moment date module
    MatMomentDateModule,
    // Material
    MatButtonModule,
    MatIconModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    MatTableModule,        // App modules
    LayoutModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSliderModule, RouterModule.forChild(routes),
    MatBadgeModule,MatCheckboxModule,FormsModule,ReactiveFormsModule,MatFormFieldModule,MatInputModule
  ]
})
export class ProjectsModule { }