import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatTableModule, MatDialogModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { WelcomeModule } from 'app/main/welcome/welcome.module';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { ReportsComponent } from './main/reports/reports.component';
import { ToastrModule } from 'ngx-toastr';
import {

    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
 
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatDatepickerModule,

    MatDividerModule,
    MatTabsModule,
    MatCheckboxModule,
  
    MatTooltipModule
} from "@angular/material";
import {MatProgressBarModule, MatRadioModule, MatSliderModule} from '@angular/material'
import {MatBadgeModule} from '@angular/material/badge';
import { InboxComponent } from './main/inbox/inbox.component';
import { ProjectsModule } from './main/projects/projects.module';
import { InvoicesComponent } from './main/invoices/invoices.component';
import { InvdetailsComponent } from './main/invoices/invdetails/invdetails.component';
import { ProfileModule } from './main/profile/profile.module';
import { FreelancerAcceptProjectComponent } from './main/dashboard/freelancer-accept-project/freelancer-accept-project.component';
import {DatePipe} from '@angular/common';
import { AuthGuard } from './_guards/auth.guard';
const appRoutes: Routes = [
    {
        path: "",
        redirectTo: "auth/login",
        pathMatch: "full"
    },
    {
        path        : '',
        loadChildren: './main/pages/pages.module#PagesModule',
    },
    {
        path        : 'dashboard', 
        component:DashboardComponent,
        canActivate: [AuthGuard] 
        
    },
    {
        path: "dashboard/freelancer-accept-project",
        component: FreelancerAcceptProjectComponent,
        canActivate: [AuthGuard] 
    },
    {
        path        : 'reports', 
        component:ReportsComponent,
        canActivate: [AuthGuard] 
        
    },
    {
        path        : 'inbox', 
        component: InboxComponent,
        canActivate: [AuthGuard] 
        
    },
    {
        path        : 'invoices', 
        component: InvoicesComponent,
        canActivate: [AuthGuard] 
        
    },{
        path        : 'invoices/invdetails', 
        component: InvdetailsComponent,
        canActivate: [AuthGuard] 
        
    },
    {
        path      : '**',
        redirectTo: 'auth/login'
    }
];

@NgModule({
    declarations: [
        AppComponent,DashboardComponent,ReportsComponent,InboxComponent,InvoicesComponent, InvdetailsComponent,FreelancerAcceptProjectComponent
    ],
    entryComponents: [FreelancerAcceptProjectComponent],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
                TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        ToastrModule.forRoot({
            timeOut: 20000,
            maxOpened: 1,
            autoDismiss: true,
            enableHtml: true,
            preventDuplicates: true,
            positionClass: 'toast-bottom-right',
            
          }),
        
        
        // Material
        MatButtonModule,        MatIconModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatDatepickerModule,
        MatDividerModule,
        MatTabsModule,
        MatCheckboxModule,
        MatTooltipModule,
                // Fuse modules
        FuseModule.forRoot(fuseConfig),        FuseProgressBarModule,        FuseSharedModule,        FuseSidebarModule,        FuseThemeOptionsModule,        MatTableModule,        // App modules
        LayoutModule,        WelcomeModule,ProfileModule,MatProgressBarModule, MatRadioModule, MatSliderModule,MatBadgeModule,ProjectsModule,MatDialogModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        DatePipe
      ]
})
export class AppModule
{
}
