<div class="page-layout w75 p-24 bg-white" fusePerfectScrollbar>
  <div class="white-box">
    <div class="tHeader">
      <h2>Please fill the below detail to create a ticket :</h2>
    </div>
    <div class="boxBodyInn">
      <p> Please provide your issue with relevant details and provide as much information as possible.</p>
      <form name="form" [formGroup]="form" (ngSubmit)="submit();">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="25px">


          <mat-form-field >
            <mat-label>Subject</mat-label>
            <input matInput formControlName="subject">
            <mat-error *ngIf="form.get('subject').invalid && form.get('subject').touched">
              <span *ngIf="form.get('subject').errors.required">Subject is required!!</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field >
            <mat-label>Projetc ID</mat-label>
            <input matInput formControlName="project">
            <mat-error *ngIf="form.get('project').invalid && form.get('project').touched">
              <span *ngIf="form.get('project').errors.required">Project Id is required!</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field  fxFlex="20">
            <mat-label>Department</mat-label>
            <mat-select formControlName="portal_id">
              <mat-option [value]="">Select</mat-option>
              <mat-option label="New" value="1">Admin</mat-option>
              <mat-option label="Sample" value="2">HR</mat-option>
              <mat-option label="Sample" value="3">PM</mat-option>
              <mat-option label="Sample" value="4">Management</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('portal_id').invalid && form.get('portal_id').touched">
              <span *ngIf="form.get('portal_id').errors.required">Select Department!</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field  fxFlex="20">
            <mat-label>Issue Type</mat-label>
            <mat-select formControlName="issue_type">
              <mat-option [value]=""> Select</mat-option>
              <mat-option label="New" value="Quote Creation">Quote Creation</mat-option>
              <mat-option label="Sample" value="Project Assignmment">Project Assignmment</mat-option>
              <mat-option label="Sample" value="User Login">User Login</mat-option>
              <mat-option label="Sample" value="Payments">Payments</mat-option>
              <mat-option label="Sample" value="Approval">Approval</mat-option>
              <mat-option label="Sample" value="Promotion">Promotion</mat-option>
              <mat-option label="Sample" value="Subscription">Subscription</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('issue_type').invalid && form.get('issue_type').touched">
              <span *ngIf="form.get('issue_type').errors.required">Select issue type!</span>
            </mat-error>
          </mat-form-field>

          <div fxFlex="92">

            <div class="fileUploader p20">
              <mat-label>Drag & Drop Files Here / Click "Add Files": </mat-label>
              <button mat-raised-button color="accent" type="button" matTooltip="Upload Document"
                (click)="onFileUpload()">
                Upload Attachment(s)
              </button>
              <input type="file" id="docUpload" name="docUpload" style="display:none;" multiple />
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-error *ngIf="fileError !== ''">{{fileError}}</mat-error>
                <span style="color: green;">{{fileSuccess}}</span>
              </div>

            </div>
            <div class="box-blank" *ngIf="uploaded_files_array.length>0">
              <div *ngFor="let res of uploaded_files_array">
                <p *ngFor="let res1 of res">
                  {{res1.name}}
                </p>
              </div>
            </div>
          </div>

          <div fxFlex="92">
            <mat-form-field >
              <mat-label>Additional Notes/Project Description: </mat-label>
              <textarea formControlName="message" matInput
                placeholder="Additional Notes/Project Description: "></textarea>
              <mat-error *ngIf="form.get('message').invalid && form.get('message').touched">
                <span *ngIf="form.get('message').errors.required">Project Description is required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="actWraper">

            <button type="submit" [disabled]="form.invalid" mat-flat-button color="accent" class="mr-16">Create
              Ticket</button>

          </div>
        </div>
      </form>
    </div>
  </div>


  <div class="white-box">
    <div class="tHeader">
      <h2>Ticket History:</h2>
    </div>
    <div class="boxBodyInn">
      <p> Your Tickets Dashboard / General Tickets History</p>
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">

        <div class="mr-8"> <i class="material-icons">filter_list</i> Short by </div>

        <mat-form-field  fxFlex="20">
          <mat-label>Department</mat-label>
          <mat-select (selectionChange)="departmentFilter($event,'department')">
            <mat-option label="New" [value]="''">Select Department</mat-option>
            <mat-option label="New" [value]="'1'">Admin</mat-option>
            <mat-option label="Sample" [value]="'2'">HR</mat-option>
            <mat-option label="Sample" [value]="'3'">PM</mat-option>
            <mat-option label="Sample" [value]="'4'">Management</mat-option>
            <mat-option label="Sample" [value]="'5'">IT</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field  fxFlex="20">
          <mat-label>Issue Type</mat-label>
          <mat-select (selectionChange)="departmentFilter($event,'issue_type')">
            <mat-option label="New" [value]="''">Select issue type</mat-option>
            <mat-option label="New" [value]="'Quote Creation'">Quote Creation</mat-option>
            <mat-option label="Sample" [value]="'Project Assignmment'">Project Assignmment</mat-option>
            <mat-option label="Sample" [value]="'User Login'">User Login</mat-option>
            <mat-option label="Sample" [value]="'Payments'">Payments</mat-option>
            <mat-option label="Sample" [value]="'Approval'">Approval</mat-option>
            <mat-option label="Sample" [value]="'Promotion'">Promotion</mat-option>
            <mat-option label="Sample" [value]="'Subscription'">Subscription</mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <!--list Ticket History-->
      <div *ngIf="paginate.total>0">
        <table mat-table [dataSource]="dataSource" class="table">
          <ng-container matColumnDef="second_id">
            <mat-header-cell *matHeaderCellDef fxFlex="16"> ID</mat-header-cell>
            <mat-cell *matCellDef="let product" fxFlex="16">
              <p class="text-truncate">{{product?.second_id}}</p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="subject">
            <mat-header-cell *matHeaderCellDef fxFlex="16"> Subject</mat-header-cell>
            <mat-cell *matCellDef="let product" fxFlex="16">
              <p class="text-truncate">
                {{product?.subject}}

            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="issue_type">
            <mat-header-cell *matHeaderCellDef fxFlex="16"> Issue Type</mat-header-cell>
            <mat-cell *matCellDef="let product" fxFlex="16">
              <p class="text-truncate">{{product?.issue_type}}</p>

            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="portal_id">
            <mat-header-cell *matHeaderCellDef fxFlex="16">Department</mat-header-cell>
            <mat-cell *matCellDef="let product" fxFlex="16">
              <p *ngIf="product?.portal_id==1">Admin</p>
              <p *ngIf="product?.portal_id==2">HR</p>
              <p *ngIf="product?.portal_id==3">PM</p>
              <p *ngIf="product?.portal_id==4">Management</p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef fxFlex="16">Created At</mat-header-cell>
            <mat-cell *matCellDef="let product" fxFlex="16">
              <p class="text-truncate">{{product?.created_at.date | date:'yyyy-MM-dd'}}</p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="updated_at">
            <mat-header-cell *matHeaderCellDef fxFlex="16">Updated At</mat-header-cell>
              <mat-cell *matCellDef="let product"   fxFlex="20">
                <p class="text-truncate">{{product?.updated_at?.date | date:'yyyy-MM-dd' }}</p>
              </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef fxFlex="20">Status</mat-header-cell>
            <mat-cell *matCellDef="let product" fxFlex="20">
              <p class="bdg ylbg" *ngIf="product?.status==1">Pending</p>
              <p *ngIf="product?.status==2">In-Progress</p>
              <p *ngIf="product?.status==3">Resolved</p>
              <p *ngIf="product?.status==4">Invalid</p>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
          <mat-row *matRowDef="let product; columns: displayedColumns;" class="product fList">
          </mat-row>
        </table>
        <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
          [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page"
          [pageSizeOptions]="[5,10,20,30,40,50,60]" (page)='paginateData($event)'>
        </mat-paginator>
      </div>

    </div>
    <div *ngIf="paginate.total==0" class="noDataFound"> No data available!!
    </div>
  </div>

</div>