import { Injectable } from '@angular/core';
import { Observable, Subject, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  BaseUrl: any;

  constructor(public http: HttpClient) { 
    this.BaseUrl = environment.APIEndpoint;
  }

  getSpeciality(){
    let headers = SharedService.getPutHeader();

    return this.http.get(this.BaseUrl + '/speciality', { headers });
  }

 getProfile():Observable<any> {
  let headers = SharedService.getPutHeader();

 return this.http.get(this.BaseUrl + '/freelancer/profile'+'?include=vendorSubtitler,vendorspecialities,vendortranslationlangs,vendoravailabilities,vendornativelanguage,vendorprofileimage,vendorresume,vendorcontract,vendorskill,vendorTreping', { headers });
 
}
getLanguages(){
  let headers = SharedService.getPutHeader();

  return this.http.get(this.BaseUrl + '/language', { headers });
}

getAllLanguages() {

  let headers = SharedService.getPutHeader();

  return this.http.get(this.BaseUrl + '/all_language?per_page=500', { headers });
}

getAllTimezone() {
  let headers = SharedService.getPutHeader();
  return this.http.get(this.BaseUrl + '/timezones?per_page=500', { headers });

}

getProfileStatus(){
  let headers = SharedService.getPutHeader();

  return this.http.get(this.BaseUrl + '/freelancer/profileStatus', { headers });
}

addLanguage_edit(data: any): Observable<any> {
  let headers = SharedService.getPutHeader();
  return this.http.post(this.BaseUrl + '/vendors/addtranslatorlang'  , data, { headers: headers });
}

 deleteVendorSubtitlerLanguage(id: any): Observable<any> {
    let headers = SharedService.getPutHeader();
    return this.http.delete(this.BaseUrl + '/vendor_subtitler/' + id , { headers: headers });
 }

addSubtitlerLanguage_edit(data: any): Observable<any> {
  let headers = SharedService.getPutHeader();
  return this.http.post(this.BaseUrl + '/vendors/addSubtitlerlang'  , data, { headers: headers });
}

updateLanguage(id: any, data: any): Observable<any> {
  let headers = SharedService.getPutHeader();
  return this.http.patch(this.BaseUrl + '/translatorlang/' + id , data, { headers: headers });
}

updateSubtitlerLanguage(id: any, data: any): Observable<any> {
  let headers = SharedService.getPutHeader();
  return this.http.patch(this.BaseUrl + '/subtitlerlang/' + id , data, { headers: headers });
}


 uploadFile(data):Observable<any> {
  let headers = SharedService.getFileHeader();
  return this.http.post(this.BaseUrl + '/vendors/uploadfile', data, { headers: headers });
}

updateProfile( data: any): Observable<any> {
  let headers = SharedService.getPutHeader();

  return this.http.post(this.BaseUrl + '/freelancer/editprofile', data, { headers: headers });
}

    //Add ticket
    addTicket(data: any): Observable<any> {
      let headers = SharedService.getPutHeader();
      return this.http.post(this.BaseUrl + '/new_ticket', data, { headers: headers });
    }

    //to show ticket history
    getTicketdata(query): Observable<any> {
      let headers = SharedService.getPutHeader();

      return this.http.get(this.BaseUrl + '/ticket'+ query, { headers });
    }
    
    register(data):Observable<any> {
      let headers = SharedService.getFileHeader();
      return this.http.post(this.BaseUrl + '/freelancer/register', data, { headers: headers });
    }

}
