import { Injectable } from '@angular/core';
import { Observable, Subject, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  BaseUrl: any;

  constructor(public http: HttpClient) { 
    this.BaseUrl = environment.APIEndpoint;
  }

  getProjectNewRequest(){
    let headers = SharedService.getPutHeader();

    return this.http.get(this.BaseUrl + '/freelancer/assign_project', { headers });
  }

  getProject_in_Progress_Accept(){
    let headers = SharedService.getPutHeader();

    return this.http.get(this.BaseUrl + '/freelancer/status/2', { headers });
  }


  getProjectInProgress_inProgress(){
    let headers = SharedService.getPutHeader();

    return this.http.get(this.BaseUrl + '/freelancer/status/4', { headers });
  }


  getProjectCompleted(){
    let headers = SharedService.getPutHeader();

    return this.http.get(this.BaseUrl + '/freelancer/status/5', { headers });
  }

  ProjectAssignmentReject(data: any): Observable<any> {
    let headers = SharedService.getPutHeader();
  
    return this.http.post(this.BaseUrl + '/freelancer/update_assignment_status' , data, { headers: headers });

  }

  ProjectAssignmentAccept(data: any): Observable<any> {
    let headers = SharedService.getPutHeader();
  
    return this.http.post(this.BaseUrl + '/freelancer/update_assignment_status' , data, { headers: headers });

  }

  Project_InProgress_start(data: any): Observable<any> {
    let headers = SharedService.getPutHeader();
  
    return this.http.post(this.BaseUrl + '/freelancer/update_assignment_status' , data, { headers: headers });
  }
  
  getAllAssignedProjects(){
    let headers = SharedService.getPutHeader();

    return this.http.get(this.BaseUrl + '/freelancer/status', { headers });
  }


}
