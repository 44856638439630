import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { ProjectService } from 'app/main/services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
@Component({
  selector: 'app-status-update',
  templateUrl: './status-update.component.html',
  styleUrls: ['./status-update.component.scss']
})
export class StatusUpdateComponent implements OnInit {
  specialization_name:string = '';
  source_lang :string= '';
  target_lang:string = '';
  ref:string='';
  due_date:string='';
  quantity_words:number;
  amount:string='';
  rate:number;
  rush_project:number = 0;
  project_id:string;
  duration:number;
  duration_unit:string='';
  update_status_interval:string;
  project_language_assignment_id:number;
  file_multiple_data:any=[];
  fileError: string = '';
  fileSuccess: string;
  form: FormGroup;
  uploaded_files:any=[];
  dataSource:any;
  uploaded_files_array:any=[];
  file_id_array:any=[];
  version_new:string;
  version:number;
  workflow_id:number;
  displayedColumns: string[] =  ['version', 'time_duration', 'comment', 'files'];
  constructor(private projectService : ProjectService, private _formBuilder: FormBuilder,private router: Router,
    private route: ActivatedRoute,private toastr: ToastrService,) { }

  ngOnInit() {
    this.project_language_assignment_id = parseInt(this.route.snapshot.params['id']);
    this.getProject_detail();
   
    this.form = this._formBuilder.group({
      comment : ['',Validators.compose([Validators.required])],
      percentage_completed: ['',Validators.compose([Validators.required])]
  });
  }

  getProject_detail() {
 
    this.projectService.getProject_detail(this.project_language_assignment_id)
    .subscribe((response:any) => {

      if(response) {
     
        response.data.forEach(element => {
          this.quantity_words=element.quantity_words;
          this.amount=element.amount;
          this.rate=element.rate;
          this.duration=element.duration;
          this.duration_unit=element.duration_unit;
          this.workflow_id=element.projectworkflow.workflow.id;
          this.update_status_interval= this.timeConvert(element.update_status_interval) ;
          this.due_date=element.due_date;
           element.project_language.forEach(elm => {
            this.source_lang = elm.sourcelanguage.lang_name ;
            this.target_lang=elm.targetlanguage.lang_name;
            this.rush_project = elm.project.rush_project;
            if(elm.specilization){
              this.specialization_name=elm.specilization.name;
            }
            this.ref=elm.project.reference+'-'+ this.project_language_assignment_id;
            this.version=elm.project.version;
            this.version_new=parseInt(elm.project.version)+1+'.0';
            this.project_id=elm.project_id;
            this.getProject_Assignment_Update();
          });
        });
    }
    }, (error) => {
        console.log(error);
    });
  }

   // start multiple file upload

   onFileUpload() { 
    this.file_multiple_data=[];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
        let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx','zip','txt'];
        var re = /(?:\.([^.]+))?$/;
        var files = fileUpload.files;
        var file;
          // loop through files
        for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if(file && filecheck.indexOf(ext) != -1) {
            let size = file.size/1024/1024;
            if (size > 20) {
                this.fileError = "File size should not be more than 20MB.";
                return;
            }
          this.file_multiple_data.push(file);
        }
        else {
            this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
 }
 this.addDocument(this.file_multiple_data);      
    };
   
    fileUpload.click();
  }

  // addDocument
  addDocument(file) {
    this.fileSuccess = '';
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append("file[]", file[x]);
     }
    this.projectService.uploadFile(uploadData)
    .subscribe((response) => {
      this.uploaded_files=response.data;
      this.uploaded_files_array.push(this.uploaded_files);
      response.data.forEach(element => {
      this.file_id_array.push(element.id);  
      });
        this.fileSuccess ='Uploaded successfully!!';
    }, (error) => {
     this.toastr.error("Something went wrong");
    });
  }
// end multiple file upload
// Submit

submit() {
  let filedata = this.form.value;
  filedata.project_assignment_id=this.project_language_assignment_id;
  filedata.project_file_id=this.file_id_array;
  filedata.version=this.version_new;
  filedata.workflow_id=this.workflow_id;

  this.projectService.projectAssignmentUpdate(filedata)
  .subscribe((response) => {
    this.toastr.success("Data updated Successfully");
    this.router.navigate(['/dashboard']);
  }, (error) => { 
    this.toastr.error("Something went wrong");
  });
}

// Get Project Assignment Update History
getProject_Assignment_Update() {
  this.projectService.getProject_Assignment_Update(this.project_id)
  .subscribe((response:any) => {
    if(response) {
   this.dataSource  = new MatTableDataSource(response.data);
  }
  }, (error) => {
      console.log(error);
  });
}


         // calulate time from minute

         timeConvert(n) {

          if(n<0){
            n=-1*n;
          }
          var num = n;
          var hours = (num / 60);
          var rhours = Math.floor(hours);
          var days = (hours / 24);
          var rdays = Math.floor(days);
          var minutes = (hours - rhours) * 60;
          var rminutes = Math.round(minutes);
         
          return  rhours + " hour(s) and " + rminutes + " minute(s)";
          }
}
