import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot ,Router} from '@angular/router';
import { SharedService } from 'app/main/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot){
    if (SharedService.get(SharedService.LOGIN) == 'true') {
      return true;
    }
    this.router.navigate(['auth/login']);
    return false;
  }
}
