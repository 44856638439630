import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'menu',
        title    : 'Menu',
        translate: 'Menu',
        type     : 'group',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                translate: 'Dashboard',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/dashboard',
                
            },
            {
                id       : 'projects',
                title    : 'Projects',
                translate: 'Projects',
                type     : 'item',
                icon     : 'assessment',
                url      : '/projects',
                
            },
            // {
            //     id       : 'invoice',
            //     title    : 'Invoice',
            //     translate: 'Invoice',
            //     type     : 'item',
            //     icon     : 'attach_money',
            //     url      : '/invoices',
            // }
        ]
    }
];
