<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>

  <div class="white-box">


      <div class="tHeader">

          <h2> <mat-icon class="ml-24">file_copy</mat-icon> <span [matBadge]="new_request.length" matBadgeOverlap="false" matBadgeColor="accent">New Assignments</span> </h2>
          
            
          
          </div>

<div class="boxBody" >

   <div *ngIf="new_request.length>0">
      <mat-table class="products-table" #table [dataSource]="dataSource_NewRequest" matSort  fusePerfectScrollbar>
    
            <ng-container matColumnDef="ref">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Reference</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxFlex="28" >
                
                    <div fxLayout="column" class="pt-16">
                        <p class="text-truncate">{{product?.ref}}</p>
                    </div>
                 </mat-cell>    
            </ng-container>

            <ng-container matColumnDef="project_type">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Project Type</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxFlex="28" >
                    <div fxLayout="column" class="pt-16">
                        <p class="text-truncate">{{product?.project_workflow?.workflow?.name}}</p>
                    </div>
                </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">Description</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                    <p class="price text-truncate">
                        <span *ngFor="let res of product.lang_array">
                            <p>{{res}} </p>
                            </span>
                    </p>
                </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="duedate">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Due Date</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
  
  
                    <span class="ml-8" >
                        {{product?.due_date }} 
                     
                  </span>
  
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="projvalue">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Projvalue</mat-header-cell>
                  <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
    
    
                      <span class="ml-8" >
                          {{product?.quantity_words}} words/ ${{product.amount}}
                       
                    </span>
    
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32"></mat-header-cell>
                  <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
    
                        <!-- <button mat-flat-button class="mr-16 b-green" color="primary">Start</button> -->
                         <button mat-flat-button class="mr-16 b-green" color="primary" (click)="openDialog(product)">Accept</button>
          
          <button mat-stroked-button class="mr-16" color="accent" routerLink="/projects/project-details/{{product.id}}">Review</button>
      
          <button mat-stroked-button color="warn" (click)="Reject(product.id)">Reject</button>
                  </mat-cell>
              </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns_new_request; sticky:true"></mat-header-row>
  
            <mat-row *matRowDef="let product; columns: displayedColumns_new_request;" 
            class="product">
            </mat-row>
  
        </mat-table>
    </div>
    
    <div  *ngIf="new_request?.length==0" class="noDataFound">
        No data available!!
      </div>

</div>

</div>

<div class="white-box">


   <div class="tHeader">

       <h2 > <mat-icon class="ml-24">file_copy</mat-icon>

          <span >Assignment in-Process</span>
         </h2> 

       </div>

    <div class="boxBody">
        <div *ngIf="in_Progress.length>0">
                <mat-table class="products-table" #table [dataSource]="dataSource_in_Progress" matSort  fusePerfectScrollbar>
                
                    <ng-container matColumnDef="ref">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Reference</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxFlex="28" >
                            <div fxLayout="column" class="pt-16">
                                <p class="text-truncate">
                                        <a routerLink="/projects/project-details/{{product.id}}" > {{product?.ref}} </a>
                                    </p>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="project_type">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Project Type</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxFlex="28" >
                            <div fxLayout="column" class="pt-16">
                                <p class="text-truncate">{{product?.project_workflow?.workflow?.name}}</p>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">Description</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                            <p class="price text-truncate">
                                <span *ngFor="let res of product.lang_array">
                                    <p>{{res}} </p>
                                    </span>
                            </p>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="duedate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Due Date</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">


                            <span class="ml-8" >
                                {{product.due_date}} 
                            
                        </span>

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Status</mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">


                                <mat-progress-bar mode="determinate" [value]="product.percentage_completed"></mat-progress-bar> 
                                
                                <div fxLayout="column">
                                <div class="smlText" fxFlexAlign="start">{{product.percentage_completed}}% Completed</div>
                                
                                <div class="smlText" fxFlexAlign="end">
                                    <!-- <span class="sText">2/3 Pending </span> -->
                                    <a class="link-text"  routerLink="/projects/update-status/{{product.id}}"> Update Status</a>
                                </div>
                                </div>
                    
                        </mat-cell>
                    </ng-container>

                    

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32"></mat-header-cell>
                        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">

                            <button mat-flat-button class="mr-16" color="accent" routerLink="/projects/finish-project/{{product.id}}">Finish</button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns_in_Progress; sticky:true"></mat-header-row>

                    <mat-row *matRowDef="let product; columns: displayedColumns_in_Progress;" 
                    class="product">
                    </mat-row>

                </mat-table>
        </div>
    
     
        <div *ngIf="Accept_data.length>0">
            <mat-table class="products-table" #table [dataSource]="dataSource_Accept" matSort  fusePerfectScrollbar>
            
                <ng-container matColumnDef="ref">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Reference</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxFlex="28" >
                        <div fxLayout="column" class="pt-16">
                            <p class="text-truncate">
                            <a routerLink="/projects/project-details/{{product.id}}"> {{product?.ref}} </a>
                            </p>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="project_type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Project Type</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxFlex="28" >
                        <div fxLayout="column" class="pt-16">
                            <p class="text-truncate">{{product?.project_workflow?.workflow?.name}}</p>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">Description</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                        <p class="price text-truncate">
                            <span *ngFor="let res of product.lang_array">
                                <p>{{res}} </p>
                                </span>
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="duedate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Due Date</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">


                        <span class="ml-8" >
                            {{product.due_date}} 
                        
                    </span>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Status</mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">

                            <mat-progress-bar mode="determinate" [value]="product.percentage_completed"></mat-progress-bar> 
                            <div class="smlText" fxFlexAlign="start">	
                                Not Started Yet
                            </div>
                            
                            <div fxLayout="column">
                            <div class="smlText" fxflexalign="end" style="align-self: flex-end;">
                                <span class="sText">{{product.difference}}  </span>
                               
                            </div>
                            </div>
                    </mat-cell>
                </ng-container>

                

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32"></mat-header-cell>
                    <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">

                            <button mat-flat-button class="mr-16 b-green" color="primary" (click)="Project_InProgress_start(product.id)">Start</button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns_Accept; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let product; columns: displayedColumns_Accept;" 
                class="product">
                </mat-row>

            </mat-table>
        </div>

     
    </div>

</div>

<div class="white-box">


      <div class="tHeader">
   
          <h2 > <mat-icon class="ml-24">file_copy</mat-icon> <span [matBadge]="completed_count" matBadgeOverlap="false" matBadgeColor="accent">Completed Project</span></h2>
   
          </div>
   
   <div class="boxBody">
  <div *ngIf="request_completed.length">
   <mat-table class="products-table" #table [dataSource]="dataSource_Completed" matSort  fusePerfectScrollbar>
            
    <ng-container matColumnDef="ref">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Reference</mat-header-cell>
        <mat-cell *matCellDef="let product"  fxFlex="28" >
            <div fxLayout="column" class="pt-16">
                <p class="text-truncate">
              {{product.ref}} 
                </p>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="project_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Project Type</mat-header-cell>
        <mat-cell *matCellDef="let product"  fxFlex="28" >
            <div fxLayout="column" class="pt-16">
                <p class="text-truncate">{{product?.project_workflow?.workflow?.name}}</p>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">Description</mat-header-cell>
        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
            <p class="price text-truncate">
                <span *ngFor="let res of product.lang_array">
                    <p>{{res}} </p>
                    </span>
            </p>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="duedate">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Due Date</mat-header-cell>
        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">


            <span class="ml-8" >
                {{product.due_date}} 
            
           </span> 

        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Status</mat-header-cell>
        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">

                <mat-progress-bar mode="determinate" [value]="product.completed_percentage" color="accent"></mat-progress-bar> 
               
                <div fxLayout="column">
                    <div class="smlText" fxFlexAlign="start"><b> {{product.difference}}</b>  Expensed</div>
                   </div>
        </mat-cell>
    </ng-container>

    

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32"></mat-header-cell>
        <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
                <div mat-cell class="actColumn align-right"> 
                        ${{product.amount}}
                        <div class="txt-blue">Billed</div>
                </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

    <mat-row *matRowDef="let product; columns: displayedColumns;" 
    class="product">
    </mat-row>

</mat-table>
</div>


       <div  *ngIf="request_completed?.length==0" class="noDataFound">
        No data available!!
      </div>
   </div>
   
   
   
   
   
   </div>


</div>