import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})

export class ProjectsComponent implements OnInit {
 responseData:any=[];
 dataSource:any;
 displayedColumns: string[] = ['name', 'project_type','description', 'duedate', 'projvalue','status'];
 ProjectAssignedStatus: any= [];

 constructor(
  private dashboardService : DashboardService,private httpService: HttpClient,
  private router: Router,private datePipe: DatePipe) {}

  ngOnInit() {
    this.httpService.get('/assets/ProjectAssignedStatus.json').subscribe(
      data => {
        this.ProjectAssignedStatus = data as string [];	 // FILL THE ARRAY WITH DATA.
  
      },
      (err: HttpErrorResponse) => {
        console.log (err.message);
      }
    );
    this.getAllAssignedProjects();
  }

  
  getAllAssignedProjects() {
    this.dashboardService.getAllAssignedProjects()
    .subscribe((response:any) => {
      if(response.data) {
        response.data.forEach(element => {
          let project_status='';
          if(this.ProjectAssignedStatus.length>0){
            this.ProjectAssignedStatus.forEach(ele => {
              if(ele.id==element.status){
                project_status=ele.value;
              }
            });
            element.project_status=project_status;
          }
          let lang1 = '';
          let ref='';
          let lang_array=[];
          let due_date='';
          due_date=element.due_date;
          element.projectlanguage.forEach(elm => {
              lang1 = elm.sourcelanguage.lang_name+ ' - ' +elm.targetlanguage.lang_name+ ',' ;
              lang_array.push(lang1);
              
                if(elm.project){
                  ref=elm.project.reference +'-'+element.id;
                }
            
              
            
          });

          if(lang_array.length>0){
              let test;
              test=lang_array[lang_array.length-1];
              lang_array[lang_array.length-1]=test.slice(0,-1)
          }
          element.lang_array = lang_array;
          element.lang_array=lang_array;
          element.ref=ref;
          var ddMMyyyy = this.datePipe.transform(due_date,"EEEE, MMMM d, y");
          element.due_date=ddMMyyyy;
        });
        this.responseData=response.data;
        this.dataSource  = new MatTableDataSource(response.data);
    }
    }, (error) => {
        console.log(error);
    });
  }


}
