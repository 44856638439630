import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatSnackBar} from '@angular/material';
import {  Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(public snackBar: MatSnackBar, private router: Router) {
  }

  static TEAM_ID: string = 'teamID';
  static LOGIN: string = 'login';
  static TOKEN: string = 'token';
  static ID: string = 'id';
  static USER_NAME: string = 'username';
  static EMAIL: string = 'email';
  static PROFILE_PIC: string = 'profile_pic';
  static TEAM_LIST: string = 'teamlist';
  static SELECTED_TEAM: string = 'selectedTeam';
  static VIEW_CONTACT: string = 'viewcontact';
  static NAVIGATION: string = 'navigation';
  static user_group_id: string = 'user_group_id';

  static get(key: string) {
    return localStorage.getItem(key);
  }

  static clearStorage() {
    localStorage.clear();
  }

  static set(key: string, value) {
    localStorage.setItem(key, value);
  }

  static getHeader() {
    if (localStorage.getItem('token') != null) {
      return new HttpHeaders()
        .set('Authorization', localStorage.getItem('token'))
        .set('Accept', 'application/json')
        .set('Access-Control-Allow-Methods', "GET, POST, DELETE, PUT,PATCH");
    }
    return null;
  }

  static getPutHeader() {
    return new HttpHeaders().set(
      'Authorization',
      localStorage.getItem('token')
    ).set('Content-Type', 'application/json');
  }

  handleError(err) {
    let message = 'Something went wrong. Please try later!!';
    this.snackBar.open(message, null, {
        duration: 3000,
        panelClass: 'msg-danger'
    });
  }

  static getFileHeader() {
    if (localStorage.getItem('token') != null) {
      return new HttpHeaders()
        .set('Authorization', localStorage.getItem('token'))
    
    }
    return null;
  }
}
