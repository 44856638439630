<div class="page-layout projectPage blank p-24 bg-white" fusePerfectScrollbar>

   <div class="white-box">


      <div class="tHeader">

         <h2>
            <mat-icon class="ml-24">file_copy</mat-icon> <span [matBadge]="responseData.length" matBadgeOverlap="false" matBadgeColor="accent">Project</span>
         </h2>
      </div>

      <div class="boxBody" *ngIf="responseData.length>0">

   
         <mat-table class="products-table" #table [dataSource]="dataSource" matSort  fusePerfectScrollbar>
               <ng-container matColumnDef="name">
                   <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Name</mat-header-cell>
                   <mat-cell *matCellDef="let product"  fxFlex="28" >
                       <div fxLayout="column" class="pt-16">
                           <p class="text-truncate">{{product.ref}}</p>
                       </div>
                   </mat-cell>
               </ng-container>

               <ng-container matColumnDef="project_type">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Project Type</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxFlex="28" >
                    <div fxLayout="column" class="pt-16">
                        <p class="text-truncate">{{product?.project_workflow?.workflow?.name}}</p>
                    </div>
                </mat-cell>
            </ng-container>
     
               <ng-container matColumnDef="description">
                   <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">Description</mat-header-cell>
                   <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
                       <p class="price text-truncate">
                           <span *ngFor="let res of product.lang_array">
                               <p>{{res}} </p>
                               </span>
                       </p>
                   </mat-cell>
               </ng-container>
     
               <ng-container matColumnDef="duedate">
                   <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Due Date</mat-header-cell>
                   <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
     
     
                       <span class="ml-8" >
                           {{product.due_date }} 
                        
                     </span>
     
                   </mat-cell>
               </ng-container>

               <ng-container matColumnDef="projvalue">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Projvalue</mat-header-cell>
                  <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
                      <span class="ml-8" >
                          {{product.quantity_words}} words/ ${{product.amount}}
                       
                    </span>
                  </mat-cell>
              </ng-container>
   
               <ng-container matColumnDef="status">
                   <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Status</mat-header-cell>
                   <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
     
     
                       <span class="ml-8" *ngIf="product.project_status!=''">
                           {{ product.project_status }} 
                        
                       </span>
     
                     </mat-cell>
                  </ng-container>
   
     
               <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
     
               <mat-row *matRowDef="let product; columns: displayedColumns;" 
               class="product">
               </mat-row>
           </mat-table>
   </div>

   <div  *ngIf="responseData?.length==0" class="noDataFound">
    No data available!!
  </div>
   </div>


</div>