<h2 mat-dialog-title>       {{ref}}</h2>
<mat-dialog-content class="mat-typography">
  <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
    <div class="item item-1" fxFlex="20%">
      <p class="dLabel">Source Language</p>
      <p class="dValue">{{source_lang}}</p>
    </div>
    <div class="item item-2" fxFlex="20%">
      <p class="dLabel">Target Language</p>
      <p class="dValue">{{target_lang}}</p>
    </div>
    <div class="item item-3" fxFlex="20%">
      <p class="dLabel">Specialization</p>
      <p class="dValue">{{specialization_name}}</p>
      
    </div>
    <div class="item item-4" fxFlex="20%">
      <p class="dLabel">Estimated Time</p>
      <p class="dValue" *ngIf="duration">{{duration}}
        <span *ngIf="duration_unit==1">Minutes</span>
        <span *ngIf="duration_unit==2">Hours</span>
        <span *ngIf="duration_unit==3">Days</span>
      </p>
    </div>
    <div class="item item-4" fxFlex="20%">
        <p class="dLabel">Duedate</p>
        <p class="dValue">{{due_date}}</p>
      </div>
  
  </div>


  <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
    <div class="item item-1" fxFlex="20%">
      <p class="dLabel">Scheduled Update</p>
      <p class="dValue" *ngIf="update_status_interval">{{update_status_interval}} </p>
    </div>
    <div class="item item-2" fxFlex="20%">
      <p class="dLabel">Volume(Source words)</p>
      <p class="dValue">{{quantity_words}}</p>
    </div>
    <div class="item item-3" fxFlex="20%">
      <p class="dLabel">Target Country(Dilect)</p>
      <p class="dValue"></p>
    </div>
    <div class="item item-4" fxFlex="20%">
      <p class="dLabel">Rate</p>
      <p class="dValue" *ngIf="rate">${{rate}} USD</p>
    </div>
    <div class="item item-5" fxFlex="20%">
      <p class="dLabel">Total Value</p>
      <p class="dValue" *ngIf="amount"><b class="txt-green"> ${{amount}} USD</b></p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actWraper">
    <button mat-flat-button mat-dialog-close color="accent"  class="mr-12" routerLink="/projects/project-details/{{project_language_assignment_id}}">Accept</button>
    <button mat-flat-button mat-dialog-close  color="warn" class="mr-12">Cancel</button>

  <button mat-stroked-button  mat-dialog-close routerLink="/projects/project-details/{{project_language_assignment_id}}">View More</button>

</mat-dialog-actions>
