import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { SharedService } from 'app/main/shared.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { navigation } from 'app/navigation/navigation';
import { ProjectService } from 'app/main/services/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    profile_pic:string;
    user_name:string;
    notification_response =[];
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private projectService: ProjectService,
        private router: Router,
        private toastr: ToastrService
    )
    {

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'tr',
                title: 'Turkish',
                flag : 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

       this.profile_pic = SharedService.get('profile_pic');
        this.user_name = SharedService.get('username');
        
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});
        this.getUnreadNotifications();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
     
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('login');
        localStorage.removeItem('token');
        
        this.router.navigate(['/user/login']);

}

profile() {
    this.router.navigate(['/profile']);

  }

  getUnreadNotifications(){
   
    this.projectService.unreadNotification()
        .subscribe((response:any) => {
            response.data.forEach(element => {
                if(element.type== "App\\Notifications\\ProjectAssignmentNotification"){
                    let data ={
                      heading : element.data.heading,
                      content : element.data.content,
                    }
                
                          this.notification_response.push(data);
                      
                  }
            });
        
      }, (error) => {
        //   this.toastr.error(error);
          
      });

}

notification_read(){
    let query = '';
   this.projectService.notificationRead(query)
   .subscribe((response) => {
     if (response) {
       this.toastr.success("Notification read successfully!!");
       this.notification_response=[];
       this.getUnreadNotifications();
       // window.location.href = '/pms/notifications';
    

     }

   }, (error) => {
     this.toastr.error("Someting went wrong");
   });
}


}


