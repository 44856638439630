<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon (click)="back()"> keyboard_backspace</mat-icon>
    </button>

    <span class="title-text h1">
      {{profileData.title}} {{profileData.first_name}}  {{profileData.middle_name}} {{profileData.last_name}}
    </span>
  </div>



  <div class="white-box">

    <div class="profHeader p20 bb1">


      <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">

        <div class="item item-1" fxFlex="auto">
          <div class="profImage">
            <img [src]="vendor_file_data" onerror="this.src='assets/images/avatars/profile.jpg'" width="120"/><br />
            <!-- <button mat-stroked-button>Upload Image</button> -->
          </div>
          <h2 class="aHead" *ngIf="profileData.vendornativelanguage!=null">Native in:
             <span class="txt-green" *ngIf="profileData.vendornativelanguage!=null">{{profileData.vendornativelanguage.data.lang_name}}</span>
            </h2>
            <p >Gender: <span *ngIf="profileData?.gender==1" class="txt-green">Male</span><span *ngIf="profileData?.gender==2">Female</span></p>
            <p >Age: <span class="txt-green">{{age}}</span></p>

            <h3>Notification:</h3>
              <p *ngIf="profileData?.sms_notification==1">SMS: <span class="txt-green">Yes</span></p><p *ngIf="profileData?.sms_notification==0">SMS: No</p>
              <p *ngIf="profileData?.wtsp_notification==1">WhatsApp: <span class="txt-green">Yes</span></p><p *ngIf="profileData?.wtsp_notification==0">WhatsApp: No</p>
        </div>

        <div class="item item-2" fxFlex="auto">
          <div class="invAdd">
            <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-2 text-right" fxFlex="50%">
                <p><b>Address:</b></p>
              </div>

              <div class="item text-left" fxFlex="50%">
                <p *ngIf="profileData.address1!=null">{{ profileData.address1 }}  </p>
              </div>

            </div>
            <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-2 text-right" fxFlex="50%">
                <p><b>Email:</b></p>
              </div>

              <div class="item text-left" fxFlex="50%">
                <p>{{profileData.email}}</p>
              </div>

            </div>

            <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-2 text-right" fxFlex="50%">
                <p><b>Skype ID:</b></p>
              </div>

              <div class="item text-left" fxFlex="50%">
                <p> {{profileData.skype_id}}</p>
              </div>

            </div>

            <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-2 text-right" fxFlex="50%">
                <p><b>Phone:</b></p>
              </div>

              <div class="item text-left" fxFlex="50%">
                <p>{{profileData.phone}}</p>
              </div>

            </div>

            <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0">
              <div class="item item-2 text-right" fxFlex="50%">
                <p><b>Timezone:</b></p>
              </div>

              <div class="item text-left" fxFlex="50%">
                <p>{{profileData.timezone}}</p>
              </div>

            </div>
            
            <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
              fxLayoutGap.xs="0" *ngIf="profileData.phone_sec">
              <div class="item item-2 text-right" fxFlex="50%">
                <p><b>Phone-2:</b></p>
              </div>

              <div class="item text-left" fxFlex="50%">
                <p>{{profileData.phone_sec}}</p>
              </div>

            </div>

            <div class="text-right"><button mat-stroked-button [routerLink]="['/profile/edit-profile']">Edit</button></div>

          </div>

        </div>


      </div>



    </div>










    <div class="boxBody">

      <div class="boxBodyInn">



        <h4>Translation Service</h4>


        <div class="lanPirInfo">
          <div class="content">


            <table class="simple lan-table">
              <thead>
                <tr>
                  <th>Language Combinations</th>
                  <th class="text-right">Translation <span class="smlText pt-0"> Rate(USD) / Word</span></th>
                  <th class="text-right">Proofreading <span class="smlText pt-0"> Rate (USD) / Word </span></th>
                  <th class="text-right">Dtp <span class="smlText pt-0"> Rate(USD) / Word</span></th>
                  <th class="text-right">MTPE <span class="smlText pt-0"> Rate (USD) / Word </span></th>
                  <th class="text-right">Speed <span class="smlText pt-0"> Words / Day</span></th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let langs of data?.vendortranslationlangs?.data">
                  <td>
                      <p *ngIf="langs.lang_data!=null">
                          {{langs.lang_data}}
                        </p>
                  </td>

                  <td class="text-right">
                      <p *ngIf="langs.translation_rate!=null">
                          ${{langs.translation_rate}} USD Per word
                        </p>
                  
                  </td>
                  <td class="text-right">
                      <p *ngIf="langs.proofreading_rate!=null">
                          ${{langs.proofreading_rate}} USD Per word
                        </p>
                  </td>

                  <td class="text-right">
                      <p *ngIf="langs.dtp_rate!=null">
                          ${{langs.dtp_rate}} USD Per word
                        </p>
                  
                  </td>
                  <td class="text-right">
                      <p *ngIf="langs.qa_rate!=null">
                          ${{langs.qa_rate}} USD Per word
                        </p>
                  </td>
                  <td class="text-right">
                      <p *ngIf="langs.speed!=null">
                        {{langs.speed}} 
                        </p>
                  </td>
                
                </tr>
                
              </tbody>
            </table>

            <h4>AVT</h4>
            <table class="simple lan-table">
              <thead>
                <tr>
                  <th>Language Pair</th>
                  <th>Service </th>
                  <th>Rate (USD) / minute </th>
                  <th>Speed <span class="smlText pt-0"> Words / Day</span></th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let subtitlerslang of data?.vendorSubtitler?.data">
                  <td>
                    <p *ngIf="subtitlerslang.subtitlerslang!=null">
                      {{subtitlerslang.subtitlerslang}}
                    </p>
                  </td>
                  <td>
                    <p *ngIf="subtitlerslang.skillservice!=null">
                      {{subtitlerslang.skillservice.data.name}}
                    </p> 
                  </td>
                  <td>
                    <p *ngIf="subtitlerslang.subtitle_rate!=null">
                      ${{subtitlerslang.subtitle_rate}} USD Per minute
                    </p>
                  </td>
                  <td>
                    <p *ngIf="subtitlerslang.speed!=null">
                      {{subtitlerslang.speed}} 
                      <span *ngIf="subtitlerslang.skill_service_id!=68">minutes per day</span>
                      <span *ngIf="subtitlerslang.skill_service_id==68">words/minutes per day</span>
                    </p>
                  </td>
                </tr>
                
              </tbody>
            </table>


            <h4>Terping</h4>
            <table  class="simple lan-table">
              <thead>
                <tr>
                  <th>Language Combinations</th>
                  <th>Service</th>
                  <th>Qualification</th>
                  <th>Hour Rate <span class="smlText pt-0"> Rate (USD) / hours </span></th>
                  <th>Minute Rate <span class="smlText pt-0"> Rate (USD) / minutes </span></th>
                  <!-- <th>Speed <span class="smlText pt-0"> Words / Day</span></th> -->
                  <th>Flexible rate <span class="smlText pt-0"> </span></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trepinglang of profileData?.vendorTreping?.data">

                  <td>
                    <p *ngIf="trepinglang.treplang!=null">
                      {{trepinglang.treplang}}
                    </p>
                  </td>
                  <td>
                    <p *ngIf="trepinglang.trepingSkillservice.data.name!=null">{{trepinglang.trepingSkillservice.data.name}}</p>
                  </td>
                  <td>
                    <p *ngIf="trepinglang.qualification!=null">{{trepinglang.qualification}}</p>
                  </td>
                  <td>
                    <p *ngIf="trepinglang.rate_hour!=null">
                      ${{trepinglang.rate_hour}} USD Per hour
                    </p>
                  </td>
                  <td>
                    <p *ngIf="trepinglang.rate_min!=null">
                      ${{trepinglang.rate_min}} USD Per minute
                    </p>
                  </td>
                  <!-- <td>
                    <p *ngIf="trepinglang.speed!=null">
                      {{trepinglang.speed}} words per day
                    </p>
                  </td> -->
                  <td>
                    <div *ngIf="trepinglang.flexible_rate!=null">
                      <p *ngIf='trepinglang.flexible_rate=="1"'>Yes</p>
                      <p *ngIf='trepinglang.flexible_rate=="0"'>No</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="box-blank">
              <p><b>Speciality</b></p>
              <p>
                {{specialities_test}}
            </div>

            <div class="box-blank" *ngIf="skills">
              <p><b>Skills</b></p>
              <p>
                {{skills}}
              </p>
            </div>


          </div>

          <h4>Technology</h4>
          <p><b>Translation Tool Used:</b>
            <span>
              <p *ngIf="profileData?.translation_tools==2">
                Yes
              </p>
              <p *ngIf="profileData?.translation_tools==1">
                No
              </p>
              <p *ngIf="profileData?.translation_tools==3">
                Yes, but I will not use them unless requested
              </p>
            </span>


          <b>Boostlingo Affiliation :</b>
          <span>
            <p *ngIf="profileData?.boostlingo_affiliation=='1'">
              Yes
            </p>
            <p *ngIf="profileData?.boostlingo_affiliation=='0'">
              No
            </p>
          </span>

          <p *ngIf="selected_tools"><b>Tools using: </b> {{selected_tools}}</p>

          <p *ngIf="profileData.tool_licence"><b>License: </b>{{profileData.tool_licence}}</p>
          <p><b>Program/Software used for subtitles: </b>
            <span>
              <p *ngIf="profileData?.subtitles_program_software !=''">
                {{profileData.subtitles_program_software}}
              </p>
              <p *ngIf="profileData?.subtitles_program_software ==''">
               Na
              </p>
            </span>
          </p>


          <h4 *ngIf="profileData?.payment_method || profileData?.payment_method_id">Financials</h4>
         
          <p *ngIf="profileData?.payment_method"><b> Payment Method :</b> {{profileData.payment_method}} <br />
            <p *ngIf="profileData?.payment_method=='Other'"><b> Other Payment Method :</b> {{profileData.other_payment_method}} <br />
          <b *ngIf="profileData?.payment_method_id">Payment Email  :</b> {{profileData.payment_method_id}}  </p>
        </div>

        <h4 *ngIf="day_name_test">Days Available</h4>
        <p>{{ day_name_test }}</p>

        <!-- <h4>Voice Sample</h4>
        <p *ngIf="profileData?.voice_sample!=null || profileData?.voice_sample!=''"> {{ profileData?.voice_sample }}</p> -->

        <h4>Detailed cover letter</h4>

        <p>{{ profileData.cover_letter }}</p>

        <h4>Resume</h4>
        
        <p *ngIf="vendor_resume_name!=null"> {{vendor_resume_name}} <a [href]="vendor_resume_data" target="_blank" download>
            <mat-icon>vertical_align_bottom</mat-icon>
          </a>
        </p>

        <p *ngIf="vendor_resume_name==null">No Record Found
          <mat-icon>vertical_align_bottom</mat-icon>
       
      </p>
        
    
        <!-- <input type="file" id="docUpload" name="docUpload" style="display:none;"/>
        <div>
            <mat-error>{{fileError}}</mat-error>
            <span style="color: green;">{{fileSuccess}}</span>
        </div> -->

        <h4>Contract</h4>

         <p *ngIf="vendor_contract_name!=null"> {{vendor_contract_name}} <a [href]="vendor_contract_data" target="_blank"  download>
          <mat-icon>vertical_align_bottom</mat-icon>
        </a>
        </p>
        <p *ngIf="vendor_contract_name==null">No Record Found
          <mat-icon>vertical_align_bottom</mat-icon>
       
      </p>
      <!-- <h4>Important Note</h4>
      <p> {{profileData?.important_note}}</p> -->
        <div class="text-right"><button mat-stroked-button [routerLink]="['/profile/edit-profile']">Edit</button></div>

      </div>





    </div>

  </div>