import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'app/main/services/profile.service';
import { Router } from '@angular/router';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { MatTableDataSource } from "@angular/material";
import { SharedService } from 'app/main/shared.service';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  Form: any;
  final_data: any = {};
  response_data: any;
  lang_combination_id: number;
  id: number;
  languageData: any = [];
  profileData: any = {};
  file_multiple_data: any = [];
  contract_multiple_data: any = [];
  resume_multiple_data: any = [];
  selected_days: any = [];
  BaseUrl_file: any;
  dataSource: any;
  selected_tools: any = [];
  day_name_test: string;
  fileError: string = '';
  fileSuccess: string;
  contractError: string = '';
  stage_id: number;
  contractSuccess: string;
  subtitler_lang_combination_id: number;
  resumeError: string = '';
  resumeSuccess: string;
  contract_file_id: number;
  resume_file_id: number;
  profile_photo_id: number;
  first_name_test: string;
  vendor_file_data: string;
  vendor_contract_data: string;
  vendor_resume_data: string;
  vendor_contract_name: string;
  vendor_resume_name: string;
  table_data: any = [];
  table_subtitler_data: any = [];
  is_profile_complete: number;
  speciality_new_id: any = [];
  speciality_new_data: any = [];
  specialityData: any = [];
  uploaded_resume: any = [];
  selected_skills: any = [];
  important_note;
  displayedColumns = ['langPair', 'translationRate', 'proofindRate', 'dtpRate', 'qaRate', 'speed', 'actions'];
  displayedSubtitlerColumns = ['langPair', 'service', 'subtitlerRate', 'speed', 'actions'];
  dataSubtitlerSource: any;
  timezoneData: any = [];
  data: any =
    {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },

      targetlanguage: {
        data: {
          lang_name: ''
        }
      },

      target_lang_id: '',

      target_lang_name: '',
      translation_rate: '',
      proofreading_rate: '',
      dtp_rate: '',
      qa_rate: '',
      speed: '',
      bi_direction: false,


    };
  dataSubtitler: any =
    {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      target_lang_name: '',
      service: '',
      subtitle_rate: '',
      speed: '',
      bi_direction: false,
      bi_direction_test: ''
    };
    sms_notification: any;
    wtsp_notification: any;



  constructor(private profileService: ProfileService,
    private router: Router, private fb: FormBuilder, private toastr: ToastrService) {
    this.BaseUrl_file = environment.baseUrl_file;

  }

  ngOnInit() {
    this.getProfileStatus();
    this.getLanguage();
    this.getSpeciality();
    this.getProfile();

    this.getTimezone();
    this.Form = this.fb.group({
      old_password: [''],
      new_password: ['', [Validators.minLength(6)]],

      confirm_password: [
        '',
        [
          confirmPasswordValidator,

          Validators.minLength(6)

        ]
      ],

      email: [''],
      skype_id: ['', Validators.compose([Validators.required])],
      phone: [''],
      phone_sec: [''],
      gender: [''],
      dob: [''],
      timezone: [''],
      address1: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required])],
      state: ['',],
      postal_code: ['', Validators.compose([Validators.required])],
      country: ['', Validators.compose([Validators.required])],
      payment_method_id: ['', [Validators.email]],
      other_payment_method: [''],
      subtitles_program_software: [''],
      native_language_id: ['', Validators.compose([Validators.required])],
      cover_letter: [''],
      voice_sample:[''],
      tool_licence: ['0'],
      specialities: [''],
      title: ['', Validators.compose([Validators.required])],
      payment_method: ['PayPal', Validators.compose([Validators.required])],
      translation_tools: ['1'],
      sms_notification: ['', Validators.compose([Validators.required])],
      wtsp_notification: ['']

    });
  }



  getProfileStatus() {
    this.profileService.getProfileStatus()
      .subscribe((res: any) => {
        if (res) {
          this.is_profile_complete = res.is_profile_complete;
        }
      }, (error) => {
        this.toastr.error("Something went wrong, Please try later!");
      });
  }

  getSourceName(evt) {
    this.languageData.forEach(element => {
      if (element.id == evt.value) {
        this.data.sourcelanguage.data.lang_name = element.lang_name;
      }
    });
  }

  getLanguage() {
    this.profileService.getAllLanguages()
      .subscribe((res: any) => {
        if (res) {
          this.languageData = res.data;
        }
      }, (error) => {
        this.toastr.error("Something went wrong, Please try later!");
      });
  }

  getTargetName(evt) {
    this.languageData.forEach(element => {
      if (element.id == evt.value) {
        this.data.targetlanguage.data.lang_name = element.lang_name;
      }
    });
  }

  getSubtitlerSourceName(evt) {
    this.languageData.forEach(element => {
      if (element.id == evt.value) {
        this.dataSubtitler.sourcelanguage.data.lang_name = element.lang_name;
      }
    });
  }

  getSubtitlerTargetName(evt) {
    this.languageData.forEach(element => {
      if (element.id == evt.value) {
        this.dataSubtitler.targetlanguage.data.lang_name = element.lang_name;
      }
    });
  }




  getSpeciality() {
    this.profileService.getSpeciality()
      .subscribe((res) => {
        if (res) {
          this.specialityData = res;
        }
      }, (error) => {
        this.toastr.error("Something went wrong, Please try later!");
      });
  }

  getProfile() {
    this.table_data = [];
    this.table_subtitler_data = [];
    this.profileService.getProfile()
      .subscribe((response: any) => {
        if (response) {
          this.response_data = response.data;
          this.dataSource = [];
          this.id = response.data.id;
          if (response.data.profile_photo_id != null) {
            this.profile_photo_id = response.data.profile_photo_id;
          }
          if (response.data.resume_file_id != null) {
            this.resume_file_id = response.data.resume_file_id;
          }
          if (response.data.contract_file_id != null) {
            this.contract_file_id = response.data.contract_file_id;
          }

          if (response.data.stage_id != null) {
            this.stage_id = response.data.stage_id;
          }

          if (response.data.vendortranslationlangs.data.length > 0) {
            response.data.vendortranslationlangs.data.forEach(element => {
              if (element.bi_direction == 2) {
                element.bi_direction = '<>';
              }
              else if (element.bi_direction == 1 || element.bi_direction == 0) {
                element.bi_direction = '>';
              }
              this.table_data.push(element);
            });
          }

          response.data.vendorSubtitler.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            this.table_subtitler_data.push(element);
          });
          this.dataSubtitlerSource = new MatTableDataSource(this.table_subtitler_data);


          response.data.vendorTreping.data.forEach(elem => {
            if (elem.bi_direction == 2) {
              elem.bi_direction = '<>';
            } else {
              elem.bi_direction = '>';
            }
            elem.treplang = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
          });

          if (response.data.vendorskill.data.length > 0) {
            response.data.vendorskill.data.forEach(element => {
              this.selected_skills.push(element.skill_id);
            });
          }
          this.dataSource = new MatTableDataSource(this.table_data);

          this.profileData = response.data;
          // this.selected_tools=response.data.tool_names.replace(/,/g, ', ');
          if (response.data.tool_names) {
            this.selected_tools = response.data.tool_names.split(",");
          }
          if (response.data.vendoravailabilities.data.length > 0) {
            let day = '';
            response.data.vendoravailabilities.data.forEach(elem => {
              day += elem.day_name + ', ';
              this.day_name_test = day;
              this.day_name_test = this.day_name_test.slice(0, -2);

            });
          }

          if (response.data.vendoravailabilities.data.length > 0) {
            response.data.vendoravailabilities.data.forEach(element => {

              this.selected_days.push(element.day_name);
            });

          }
          if (response.data.vendorprofileimage.data.length > 0) {
            response.data.vendorprofileimage.data.forEach(element => {
              this.vendor_file_data = this.BaseUrl_file + element.path;
            });
          }
          if (response.data.vendorcontract.data.length > 0) {
            response.data.vendorcontract.data.forEach(element => {
              this.vendor_contract_data = this.BaseUrl_file + element.path;
              let x = element.path.split('/');
              this.vendor_contract_name = x[2];
            });
          }
          if (response.data.vendorspecialities.data.length > 0) {
            response.data.vendorspecialities.data.forEach(element => {
              this.speciality_new_id.push(element.specialities.data.id);

            });
          }
          if (response.data.vendorresume.data.length > 0) {
            response.data.vendorresume.data.forEach(element => {
              this.vendor_resume_data = this.BaseUrl_file + element.path;
              let x = element.path.split('/');
              this.vendor_resume_name = x[2];
            });

          }
          if (response.data.first_name) {
            this.first_name_test = response.data.first_name;
          }

          if (response.data.tool_licence) {
            this.Form.patchValue({
              tool_licence: response.data.tool_licence.toString(),
            });
          }

          if (response.data.stage_id == null) {
            this.stage_id = 9;
          } else {
            this.stage_id = response.data.stage_id;
          }
          this.important_note = response.data.important_note;
          if (response.data.translation_tools != null) {
            this.Form.patchValue({
              translation_tools: response.data.translation_tools.toString(),
            });
          }
          this.Form.patchValue({
            address1: response.data.address1,
            email: response.data.email,
            city: response.data.city,
            country: response.data.country,
            cover_letter: response.data.cover_letter,
            voice_sample:response.data.voice_sample,
            education: response.data.education,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            middle_name: response.data.middle_name,
            native_language_id: response.data.native_language_id,
            payment_method_id: response.data.payment_method_id,
            other_payment_method:response.data.other_payment_method,
            subtitles_program_software: response.data.subtitles_program_software,
            phone: response.data.phone,
            phone_sec: response.data.phone_sec,
            dob: response.data.dob,
            gender: response.data.gender,
            timezone: response.data.timezone,
            postal_code: response.data.postal_code,
            skype_id: response.data.skype_id,
            state: response.data.state,
            title: response.data.title,
            specialities: this.speciality_new_id,
            payment_method: response.data.payment_method,
            sms_notification: response.data.sms_notification,
            wtsp_notification: response.data.wtsp_notification,
          });
          if (response.data.title) {
            this.Form.patchValue({
              title: response.data.title,
            });
          } else {
            this.Form.patchValue({
              title: '',
            });
          }
        }
      }, (error) => {
      });
  }

  smsNotification(evt:any){
    if (evt.checked) {
      this.sms_notification = 1;
    }else{
      this.sms_notification = 0;
    }
  }

  wtspNotification(evt:any){
    if (evt.checked) {
      this.wtsp_notification = 1;
    }else{
      this.wtsp_notification = 0;
    }
  }


  editData(value) {
    this.data.source_lang_id = value.sourcelanguage.data.id;
    this.data.target_lang_id = value.targetlanguage.data.id;
    this.data.translation_rate = value.translation_rate;
    this.data.proofreading_rate = value.proofreading_rate;
    this.data.dtp_rate = value.dtp_rate;
    this.data.qa_rate = value.qa_rate;
    this.data.speed = value.speed;
    this.lang_combination_id = value.id;
    if (value.bi_direction == ">") {
      this.data.bi_direction = false;
    }
    else {
      this.data.bi_direction = true;
    }
  }


  editSubtitlerData(value) {
    this.dataSubtitler.source_lang_id = value.sourcelanguage.data.id;
    this.dataSubtitler.target_lang_id = value.targetlanguage.data.id;
    this.dataSubtitler.service = value.service;
    this.dataSubtitler.subtitle_rate = value.subtitle_rate;
    this.dataSubtitler.speed = value.speed;
    this.subtitler_lang_combination_id = value.id;
    if (value.bi_direction == '>') {
      this.dataSubtitler.bi_direction = false;
    }
    else {
      this.dataSubtitler.bi_direction = true;
    }
  }


  addLanguageTableApi() {
    if (this.lang_combination_id) {
      this.table_data = [];
      if (this.data) {
        this.profileService.updateLanguage(this.lang_combination_id, this.data)
          .subscribe((response) => {
            if (response) {
              this.lang_combination_id = null;
              this.edit_getTranslatorData();
              this.data = {
                source_lang_id: '',
                sourcelanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                targetlanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                target_lang_id: '',
                target_lang_name: '',
                translation_rate: '',
                proofreading_rate: '',
                dtp_rate: '',
                qa_rate: '',
                speed: '',
                bi_direction: false,
              };
            }
          }, (error) => {
            if (error) {
              this.toastr.error("Please first select all fields.");
            }
          });
      }
    }
    else {
      this.table_data = [];
      if (this.data.sourcelanguage.data.lang_name == "") {
        this.toastr.error("please select source language");
        return
      }
      if (this.data.targetlanguage.data.lang_name == "") {
        this.toastr.error("please select target language");
        return
      }
      if (this.data.translation_rate == "") {
        this.toastr.error("please select translation rate");
        return
      }
      if (this.data.proofreading_rate == "") {
        this.toastr.error("please select proofreading rate");
        return
      }
      if (this.data.speed == "") {
        this.toastr.error("please select speed");
        return
      }
      this.data.vendor_id = this.id;
      this.profileService.addLanguage_edit(this.data)
        .subscribe((response) => {
          if (response) {
            this.edit_getTranslatorData();
            this.data = {
              source_lang_id: '',
              sourcelanguage: {
                data: {
                  lang_name: ''
                }
              },
              targetlanguage: {
                data: {
                  lang_name: ''
                }
              },
              target_lang_id: '',
              target_lang_name: '',
              translation_rate: '',
              proofreading_rate: '',
              dtp_rate: '',
              qa_rate: '',
              speed: '',
              bi_direction: false,
            };
          }
        }, (error) => {
          this.toastr.error(error);
        });
    }
  }

  addSubtitlerLanguageTableApi() {
    //debugger;
    if (this.subtitler_lang_combination_id) {
      this.table_subtitler_data = [];
      if (this.dataSubtitler) {
        this.profileService.updateSubtitlerLanguage(this.subtitler_lang_combination_id, this.dataSubtitler)
          .subscribe((response) => {
            if (response) {
              this.subtitler_lang_combination_id = null;
              this.edit_getSubtitlerData();
              this.dataSubtitler = {
                source_lang_id: '',
                sourcelanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                targetlanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                target_lang_id: '',
                target_lang_name: '',
                service: '',
                subtitle_rate: '',
                speed: '',
                bi_direction: false,
                bi_direction_test: ''
              };
            }
          }, (error) => {
            if (error) {
              this.toastr.error('Please first select all fields.');
            }
          });
      }

    }
    else {
      //debugger;
      this.table_subtitler_data = [];
      if (this.dataSubtitler.sourcelanguage.data.lang_name == '') {
        this.toastr.error('please select avt source language');
        return false;
      }
      if (this.dataSubtitler.targetlanguage.data.lang_name == '') {
        this.toastr.error('please select avt target language');
        return false;
      }
      if (this.dataSubtitler.service == '') {
        this.toastr.error('please select avt service');
        return false;
      }
      if (this.dataSubtitler.subtitle_rate == '') {
        this.toastr.error('please select rate');
        return false;
      }
      if (this.dataSubtitler.subtitle_rate < 0 || this.dataSubtitler.subtitle_rate == 0) {
        this.toastr.error('Avt rate never be negative or zero');
        return false;
      }

      if (this.dataSubtitler.speed == '') {
        this.toastr.error('please select speed');
        return false;
      }

      if (this.dataSubtitler.speed < 0 || this.dataSubtitler.speed == 0) {
        this.toastr.error('Speed never be negative or zero');
        return false;
      }
      this.dataSubtitler.vendor_id = this.id;
      this.profileService.addSubtitlerLanguage_edit(this.dataSubtitler)
          .subscribe((response) => {
          if (response) {
            this.edit_getSubtitlerData();
            this.dataSubtitler = {
              source_lang_id: '',
              sourcelanguage: {
                data: {
                  lang_name: ''
                }
              },
              targetlanguage: {
                data: {
                  lang_name: ''
                }
              },
              target_lang_id: '',
              target_lang_name: '',
              service: '',
              subtitle_rate: '',
              speed: '',
              bi_direction: false,
              bi_direction_test: ''
            };
          }
        });
    }
  }

  edit_getTranslatorData() {
    this.profileService.getProfile()
      .subscribe((response) => {
        if (response) {
          this.dataSource = [];
          if (response.data.vendortranslationlangs.data.length > 0) {
            response.data.vendortranslationlangs.data.forEach(element => {
              if (element.bi_direction == 2) {
                element.bi_direction = '<>';
              }
              else if (element.bi_direction == 1 || element.bi_direction == 0) {
                element.bi_direction = '>';
              }
              this.table_data.push(element);
            });
          }
          this.dataSource = new MatTableDataSource(this.table_data);
        }
      }, (error) => {
        this.toastr.error(error);
      });
  }


  edit_getSubtitlerData() {
    this.profileService.getProfile()
      .subscribe((response) => {
        if (response) {
          this.dataSubtitlerSource = [];
         // this.type = 'Update';
          response.data.vendorSubtitler.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            this.table_subtitler_data.push(element);
          });
          this.dataSubtitlerSource = new MatTableDataSource(this.table_subtitler_data);
        }
      });
  }


  deleteSubtitlerData(obj: any, index: any) {
    if (this.id) {
      this.profileService.deleteVendorSubtitlerLanguage(obj.id)
        .subscribe((response) => {
          this.toastr.success("delete successfully");
          this.getProfile();
          this.dataSubtitler = {
            source_lang_id: '',
            sourcelanguage: {
              data: {
                lang_name: ''
              }
            },
            targetlanguage: {
              data: {
                lang_name: ''
              }
            },
            target_lang_id: '',
            target_lang_name: '',
            service: '',
            subtitle_rate: '',
            speed: '',
            bi_direction: false,
            bi_direction_test: ''
          };
        });
    }
    else {
      this.table_subtitler_data.splice(index, 1);
      this.dataSubtitlerSource = new MatTableDataSource(this.table_subtitler_data);
    }


  }


  back() {
    this.router.navigate(['/profile']);
  }


  Available_days(evt: any) {
    if (evt.checked && this.selected_days.indexOf(evt.source.value == -1)) {
      this.selected_days.push(evt.source.value);
    }
    else if (!evt.checked && this.selected_days.indexOf(evt.source.value != -1)) {
      let index = this.selected_days.indexOf(evt.source.value);
      this.selected_days.splice(index, 1);
    }
  }
  tools(evt: any) {
    if (evt.checked && this.selected_tools.indexOf(evt.source.value == -1)) {
      this.selected_tools.push(evt.source.value);
    }
    else if (!evt.checked && this.selected_tools.indexOf(evt.source.value != -1)) {
      let index = this.selected_tools.indexOf(evt.source.value);
      this.selected_tools.splice(index, 1);
    }
  }

  //   upload image
  onFileUpload() {
    this.file_multiple_data = [];
    const fileUpload = document.getElementById('imgUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError = "File size should not be more than 20MB.";
            return;
          }
          this.file_multiple_data.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt or pptx format.';
        }
      }
      this.addDocument(this.file_multiple_data);
    };

    fileUpload.click();
  }

  // addDocument
  addDocument(file) {

    this.fileSuccess = '';
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append("file[]", file[x]);
    }
    this.profileService.uploadFile(uploadData)
      .subscribe((response) => {
        response.data.forEach(element => {
          this.profile_photo_id = element.id;
          this.vendor_file_data = this.BaseUrl_file + '/uploads/' + element.name;
        });
        this.fileSuccess = 'Uploaded successfully!!';

      }, (error) => {

        this.toastr.error("Something went wrong");
      });
  }


  //   upload Contract
  onContractUpload() {
    this.contract_multiple_data = [];
    const fileUpload = document.getElementById('contractUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.contractError = "File size should not be more than 20MB.";
            return;
          }
          this.contract_multiple_data.push(file);
        }
        else {
          this.contractError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt or pptx format.';
        }
      }
      this.addContract(this.contract_multiple_data);
    };

    fileUpload.click();
  }

  // addContract
  addContract(file) {

    this.contractSuccess = '';
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append("file[]", file[x]);
    }
    this.profileService.uploadFile(uploadData)
      .subscribe((response) => {
        response.data.forEach(element => {
          this.contract_file_id = element.id;
          this.vendor_contract_name = element.name;
          this.vendor_contract_data = this.BaseUrl_file + element.path;

        });
        this.contractSuccess = 'Uploaded successfully!!';

      }, (error) => {

        this.toastr.error("Something went wrong");
      });
  }

  //   upload Contract
  // onContractUpload() { 

  //   const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
  //   fileUpload.onchange = () => {
  //       let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
  //       var re = /(?:\.([^.]+))?$/;

  //       var ext = re.exec(fileUpload.files[0].name)[1];

  //       if(fileUpload.files[0] && filecheck.indexOf(ext) != -1) {
  //           let size = fileUpload.files[0].size/1024/1024;
  //           if (size > 20) {
  //               this.fileError = "File size should not be more than 20MB.";
  //               return;
  //           }
  //           this.addContract(fileUpload.files[0]);
  //       }
  //       else {
  //           this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, ppt or pptx format.';
  //       }
  //   };
  //   fileUpload.click();
  // }


  // addContract(file) {

  //   this.fileSuccess = '';

  //   const uploadData = new FormData();
  //   uploadData.append('file', file);

  //   this.profileService.uploadFile(uploadData)
  //   .subscribe((response) => {
  //     this.contract_file_id =response.id;
  //      this.vendor_contract_name=file.name;

  //   }, (error) => {

  //    this.toastr.error(error);
  //   });
  // }


  //   upload Resume

  onResumeUpload() {
    this.resume_multiple_data = [];
    const fileUpload = document.getElementById('resumeUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.resumeError = "File size should not be more than 20MB.";
            return;
          }
          this.resume_multiple_data.push(file);
        }
        else {
          this.resumeError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt or pptx format.';
        }
      }
      this.addResume(this.resume_multiple_data);
    };

    fileUpload.click();
  }

  // addContract
  addResume(file) {

    this.resumeSuccess = '';
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append("file[]", file[x]);
    }
    this.profileService.uploadFile(uploadData)
      .subscribe((response) => {
        response.data.forEach(element => {
          this.resume_file_id = element.id;
          this.vendor_resume_name = element.name;
          this.vendor_resume_data = this.BaseUrl_file + element.path;

        });
        this.resumeSuccess = 'Uploaded successfully!!';
      }, (error) => {
        this.toastr.error("Something went wrong");
      });
  }
  // onResumeUpload() { 

  //   const fileUpload = document.getElementById('resumeUpload') as HTMLInputElement;
  //   fileUpload.onchange = () => {
  //       let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
  //       var re = /(?:\.([^.]+))?$/;

  //       var ext = re.exec(fileUpload.files[0].name)[1];

  //       if(fileUpload.files[0] && filecheck.indexOf(ext) != -1) {
  //           let size = fileUpload.files[0].size/1024/1024;
  //           if (size > 20) {
  //               this.fileError = "File size should not be more than 20MB.";
  //               return;
  //           }
  //           this.addResume(fileUpload.files[0]);
  //       }
  //       else {
  //           this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, ppt or pptx format.';
  //       }
  //   };
  //   fileUpload.click();
  // }


  // addResume(file) {

  //   this.fileSuccess = '';

  //   const uploadData = new FormData();
  //   uploadData.append('file', file);

  //   this.profileService.uploadFile(uploadData)
  //   .subscribe((response) => {
  //     this.resume_file_id =response.id;
  //      this.vendor_resume_name=file.name;

  //   }, (error) => {

  //    this.toastr.error(error);
  //   });
  // }

  Skills(evt: any) {
    if (evt.checked && this.selected_skills.indexOf(evt.source.value == -1)) {
      this.selected_skills.push(evt.source.value);
    }
    else if (!evt.checked && this.selected_skills.indexOf(evt.source.value != -1)) {
      let index = this.selected_skills.indexOf(evt.source.value);
      this.selected_skills.splice(index, 1);
    }
  }
  getTimezone(){
    this.profileService.getAllTimezone()
      .subscribe((res) => {
        if (res) {
          this.timezoneData = res;
        }
      });
  }

  submit() {
    this.final_data = this.Form.value;
    if (this.selected_days.length == 0) {
      this.toastr.error("Please select any days!!");
      return false;
    }
    this.final_data.translation_tools = this.Form.value.translation_tools;
    this.final_data.specialities = this.Form.value.specialities.toString();
    this.final_data.tool_names = this.selected_tools.toString();
    this.final_data.availability = this.selected_days.toString();
    this.final_data.resume_file_id = this.resume_file_id;
    this.final_data.contract_file_id = this.contract_file_id;
    this.final_data.profile_photo_id = this.profile_photo_id;
    this.final_data.is_profile_complete = "1";
    this.final_data.stage_id = this.stage_id;
    this.final_data.vendor_skill = this.selected_skills;
    this.final_data.important_note = this.important_note;
    console.log(this.final_data);
    this.profileService.updateProfile(this.final_data).subscribe(
      resposne => {
        if (resposne) {
          // SharedService.set(SharedService.PROFILE_PIC, this.vendor_file_data );
          // SharedService.set(SharedService.USER_NAME, this.profileForm.value.first_name);
          SharedService.set(SharedService.PROFILE_PIC, this.vendor_file_data);
          this.toastr.success('Profile updated successfully');
          window.location.href = '/profile';

        }
      },
      error => {
        this.toastr.error('Something went wrong!!');
      }
    );

  }




}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('new_password');
  const passwordConfirm = control.parent.get('confirm_password');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

