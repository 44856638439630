import { Component, OnInit } from '@angular/core'; 
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DashboardService } from '../services/dashboard.service';
import { MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { FreelancerAcceptProjectComponent } from './freelancer-accept-project/freelancer-accept-project.component';
export interface Food {
  name: string;
  description: string;
  duedate: string;
  projvalue: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dataSource_Accept:any;
  dataSource_in_Progress:any;
  dataSource_NewRequest:any;
  dataSource_Completed:any;
  new_request_count:number;
  completed_count:number;
  paginate_in_Progress:any = {};
  array_inprogress:any=[];
  in_Progress:any=[];
  Accept_data:any=[];
  new_request:any=[];
  request_completed:any=[];
  array_NewRequest:any=[];
  dataSource: Food[] = [
    {name: 'Project Name', description: 'Hindi <> English Translation', duedate:'Tuesday, May 17th 2019', projvalue: '830 Words / $120.00'},
    {name: 'Project Name', description: 'Hindi <> English Translation', duedate:'Tuesday, May 17th 2019', projvalue: '830 Words / $120.00'},
    {name: 'Project Name', description: 'Hindi <> English Translation', duedate:'Tuesday, May 17th 2019', projvalue: '830 Words / $120.00'},
 ];
 displayedColumns: string[] = ['ref','project_type', 'description', 'duedate', 'status', 'actions'];
 displayedColumns_new_request: string[] =  ['ref', 'project_type','description', 'duedate', 'projvalue', 'actions'];
 displayedColumns_in_Progress: string[] = ['ref', 'project_type', 'description', 'duedate', 'status', 'actions'];
 displayedColumns_Accept: string[] = ['ref','project_type', 'description', 'duedate', 'status', 'actions'];

 constructor(public dialog: MatDialog,
  private dashboardService : DashboardService,
  private toastr: ToastrService,private router: Router,private datePipe: DatePipe) {}

  ngOnInit() {
    this.getProject_NewRequest();
    this.getProject_in_Progress_Accept();
    this.getProject_in_Progress_InProgress();
    this.getProject_Completed();
  }


  getProject_NewRequest() {
  
    this.dashboardService.getProjectNewRequest()
    .subscribe((response:any) => {
      if(response) {
        response.data.forEach(element => {
          let lang1 = '';
          let ref='';
          let lang_array=[];
          let due_date='';
          due_date=element.due_date;
          element.projectlanguage.forEach(elm => {
              lang1 = elm.sourcelanguage.lang_name+ ' - ' +elm.targetlanguage.lang_name+ ',' ;
              lang_array.push(lang1);
              ref=elm.project.reference +'-'+element.id;
          });

          if(lang_array.length>0){
              let test;
              test=lang_array[lang_array.length-1];
              lang_array[lang_array.length-1]=test.slice(0,-1)
          }
          element.lang_array = lang_array;
          element.lang_array=lang_array;
          element.ref=ref;
          var ddMMyyyy = this.datePipe.transform(due_date,"EEEE, MMMM d, y");
          element.due_date=ddMMyyyy;
        });
        this.new_request=response.data;
        this.new_request_count=response.data.length;
        this.dataSource_NewRequest  = new MatTableDataSource(response.data);
    }
        
    }, (error) => {

        console.log(error);
    });
  }

  getProject_in_Progress_Accept() {
  
    this.dashboardService.getProject_in_Progress_Accept()
    .subscribe((response:any) => {

      if(response) {
     
        response.data.forEach(element => {

          let lang1 = '';
          let ref='';
          let lang_array=[];
          let due_date='';
          due_date=element.due_date;
          element.projectlanguage.forEach(elm => {
              lang1 = elm.sourcelanguage.lang_name+ ' - ' +elm.targetlanguage.lang_name+ ',' ;
              lang_array.push(lang1);
              ref=elm.project.reference +'-'+element.id;
          });

          if(lang_array.length>0){
              let test;
              test=lang_array[lang_array.length-1];
              lang_array[lang_array.length-1]=test.slice(0,-1)
              
          }
          element.lang_array = lang_array;
          element.lang_array=lang_array;
          element.ref=ref;
          var ddMMyyyy = this.datePipe.transform(due_date,"EEEE, MMMM d, y");
    
          element.due_date=ddMMyyyy;

          // due time

          var DueTime: any;
          let dt2=new Date();
          var datetime= moment(element.created_at);
          var current= moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
          var minutes=current.diff(datetime,'minutes');
          var seconds= current.diff(datetime, 'seconds');
          var hour= current.diff(datetime, 'hours');
          var day= current.diff(datetime, 'day');
          var months= current.diff(datetime, 'month');
          if(day>30){
              
              if(months==1){
                  DueTime = months +' Month Passed';
              }
              else{
                  DueTime = months +' Months Passed'; 
              }
          }
          else if(hour>24){
              if(day==1){
                  DueTime = day +' Day Passed';
              }
              else{
                  DueTime = day +' Days Passed'; 
              }
          }
          else if(minutes>60)
          {
           
              if(hour==1){
                  DueTime = hour + ' Hour Passed';
              }
              else{
                  DueTime = hour + ' Hours Passed';
              }
          }
          else if(seconds>60)
          {
           
              if(minutes==1){
                  DueTime = minutes +' Mintue Passed';
              }
              else{
                  DueTime = minutes +' Mintues Passed';
              }
          }
          else if(seconds<60)
          {
              if(seconds==1){
                  DueTime = seconds +' Second Passed';
              }
              else{
                  DueTime = seconds +' Seconds Passed';
              }
         
          }
         element['difference']= DueTime;


          
        });
        this.Accept_data=response.data;
        this.dataSource_Accept  = new MatTableDataSource(response.data);
    }

    }, (error) => {

        console.log(error);
        
    });
  }

  getProject_in_Progress_InProgress() {
  
    this.dashboardService.getProjectInProgress_inProgress()
    .subscribe((response:any) => {

      if(response) {
     
        response.data.forEach(element => {

          let lang1 = '';
          let ref='';
          let lang_array=[];
          let due_date='';
          due_date=element.due_date;
          element.projectlanguage.forEach(elm => {
              lang1 = elm.sourcelanguage.lang_name+ ' - ' +elm.targetlanguage.lang_name+ ',' ;
              lang_array.push(lang1);
              ref=elm.project.reference+ '-' + element.id;
          });

          if(lang_array.length>0){
              let test;
              test=lang_array[lang_array.length-1];
              lang_array[lang_array.length-1]=test.slice(0,-1)
              
          }
          element.lang_array = lang_array;
          element.lang_array=lang_array;
          element.ref=ref;
          var ddMMyyyy = this.datePipe.transform(due_date,"EEEE, MMMM d, y");
    
          element.due_date=ddMMyyyy;
          
        });
        this.in_Progress =response.data;
        this.dataSource_in_Progress  = new MatTableDataSource(response.data);

    }

    }, (error) => {

        console.log(error);
        
    });
  }

  Reject(project_language_assignment_id:any){
    let obj:any={
      project_language_assignment_id:project_language_assignment_id,
      status:'6'
    }
    this.dashboardService.ProjectAssignmentReject(obj)
    .subscribe((response) => {
        if(response) {
          this.toastr.success("Project Assignment Reject Successfully");
          this.getProject_NewRequest();
          this.getProject_in_Progress_Accept();
          this.getProject_in_Progress_InProgress();
        }
        else{
          this.toastr.error("Something went wrong");
        }
    }, (error) => {
        this.toastr.error("Someting went wrong");
    });
  }


  openDialog(data) {
      
    const dailogRef = this.dialog.open(FreelancerAcceptProjectComponent, {
        width: '1400px',
        data: data
    });
  }


  

  Project_InProgress_start(project_language_assignment_id:any){
    let obj:any={
      project_language_assignment_id:project_language_assignment_id,
      status:'4'
    }
    this.dashboardService.Project_InProgress_start(obj)
    .subscribe((response) => {
        if(response) {
          this.toastr.success("Project In-Progress Successfully");
          this.getProject_NewRequest();
          this.getProject_in_Progress_Accept();
          this.getProject_in_Progress_InProgress();
        }
        else{
          this.toastr.error("Something went wrong");
        }
    }, (error) => {
        this.toastr.error("Someting went wrong");
    });
  }


  getProject_Completed() {
  
    this.dashboardService.getProjectCompleted()
    .subscribe((response:any) => {

      if(response) {
      
        response.data.forEach(element => {

          let lang1 = '';
          let ref='';
          let lang_array=[];
          let due_date='';
          due_date=element.due_date;
          element.projectlanguage.forEach(elm => {
              lang1 = elm.sourcelanguage.lang_name+ ' - ' +elm.targetlanguage.lang_name+ ',' ;
              lang_array.push(lang1);
              ref=elm.project.reference +'-'+element.id;
          });

          if(lang_array.length>0){
              let test;
              test=lang_array[lang_array.length-1];
              lang_array[lang_array.length-1]=test.slice(0,-1)
              
          }
          element.lang_array = lang_array;
          element.lang_array=lang_array;
          element.ref=ref;
          var ddMMyyyy = this.datePipe.transform(due_date,"EEEE, MMMM d, y");
          element.due_date=ddMMyyyy;

          // Expense in hours
          var assignment_time: any;
          let dt2=new Date();
          var datetime= moment(element.assignment_time);
          var current= moment(dt2.toISOString(), "YYYY-MM-DD HH:mm:ss.SSSS");
          var minutes=current.diff(datetime,'minutes');
          var hour= current.diff(datetime, 'hours');
          if(minutes>60)
          {
           
              if(hour==1){
                assignment_time = hour + ' Hour Passed';
              }
              else{
                assignment_time = hour + ' Hours Passed';
              }
          }else{
            assignment_time = hour + ' Hour Passed';
          }
          
          element['difference']= assignment_time;
        });
        this.request_completed=response.data;
        this.completed_count=response.data.length;
        this.dataSource_Completed  = new MatTableDataSource(response.data);


    }
        
    }, (error) => {

        console.log(error);
    });
  }

}

