import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'app/main/services/profile.service';

import { Router } from '@angular/router';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profileData:any={};
  BaseUrl_file: any;
  age: any = '';
  specialities_test:string;
  selected_tools : string;
  day_name_test:string;
  fileError: string = '';
  fileSuccess: string; 
  file_id:number;
  vendor_file_data:string;
  vendor_contract_data:string;
  vendor_resume_data:string;
  vendor_contract_name:string;
  vendor_resume_name:string;
  skills: string;
data:any = {

  tool_licence: '',
  vendoravailabilities: {
    data:[]
  },
  vendorspecialities : {
    data:[]
  },
  country: '',
  first_name: '',
  last_name: '',
  reference_id: '',
  email: '',
  skype_id: '',
  address1: '',
  city: '',
  state: '',
  postal_code: '',
  payment_method: '',
  translation_tools: '',
  education: '',
  cover_letter: '',
  vendornativelanguage: {},
  sms_notification:'',
  wtsp_notification:'',


  }

  constructor(private profileService : ProfileService,
     private router: Router,) { 
      this.BaseUrl_file = environment.baseUrl_file;
    
  }

  ngOnInit() {
    this.getProfile();
  }

  getProfile(){
    this.profileService.getProfile()
    .subscribe((response:any) => {
        if(response) {
        
        this.profileData = response.data;

        if(response.data.dob !='' && response.data.dob !=null){
          let bdate = new Date(response.data.dob);
          let ntimeDiff = Math.abs(Date.now() - bdate.getTime() );
          this.age = Math.floor((ntimeDiff / (1000 * 3600 * 24)) / 365);
        }

        if(response.data.tool_names){
          this.selected_tools = response.data.tool_names.replace(/,/g, ', ');
        }
       
          if(response.data.tool_licence == 0){
            this.profileData.tool_licence = "No";
          }
          else{
            this.profileData.tool_licence = "Yes";

          }

          if (response.data.vendorspecialities.data.length > 0){
            let specialities = '';
            response.data.vendorspecialities.data.forEach(elem => {
              if(elem.specialities !== undefined)
              {
             specialities += elem.specialities.data.name + ', ';
             this.specialities_test = specialities;
             this.specialities_test = this.specialities_test.slice(0, -2);
              }
             });
          }

          if (response.data.vendoravailabilities.data.length > 0){
            let day = '';
            response.data.vendoravailabilities.data.forEach(elem => {
              if(elem.day_name !== undefined)
              {
                day += elem.day_name +', ';
                this.day_name_test = day;
                this.day_name_test = this.day_name_test.slice(0, -2);
              }   
            });
          }

          if (response.data.vendorprofileimage.data.length > 0){
            response.data.vendorprofileimage.data.forEach(element => {
              this.vendor_file_data = this.BaseUrl_file + element.path;
            });
          }
          if (response.data.vendorcontract.data.length > 0){
            response.data.vendorcontract.data.forEach(element => {
              this.vendor_contract_data = this.BaseUrl_file + element.path;
              let x = element.path.split('/');
              this.vendor_contract_name = x[2];
            });
          }
          if (response.data.vendorresume.data.length > 0){
            response.data.vendorresume.data.forEach(element => {
              this.vendor_resume_data = this.BaseUrl_file + element.path;
              let x = element.path.split('/');
              this.vendor_resume_name = x[2];
            });
          }


          response.data.vendorTreping.data.forEach(elem => {
            if (elem.bi_direction == 2) {
              elem.bi_direction = '<>';
            } else {
              elem.bi_direction = '>';
            }
            elem.treplang = elem.sourcelanguage.data.lang_name + elem.bi_direction + elem.targetlanguage.data.lang_name;
          });

          let skills = '';
          if (response.data.vendorskill.data.length > 0) {
            response.data.vendorskill.data.sort(function (a , b) {
              return a.skill_id - b.skill_id;
            });
            response.data.vendorskill.data.forEach(elem => {
              if(elem.skill !== undefined)
              {
                skills += elem.skill.data.name + ', ';
                this.skills = skills;
                this.skills = this.skills.slice(0, -2);
              }
          
            });
          }

          if (response.data.vendortranslationlangs.data.length > 0){
            response.data.vendortranslationlangs.data.forEach(elem => {
              if(elem.bi_direction == 2){
                elem.bi_direction = '<>';
            }else{
              elem.bi_direction = '>';
            }
              elem.lang_data = elem.sourcelanguage.data.lang_name+elem.bi_direction+ elem.targetlanguage.data.lang_name;
            });
          }

          if (response.data.vendorSubtitler.data.length > 0){
            response.data.vendorSubtitler.data.forEach(elem => {
              if(elem.bi_direction == 2){
                elem.bi_direction = '<>';
            }else{
              elem.bi_direction = '>';
            }
              elem.subtitlerslang = elem.sourcelanguage.data.lang_name+elem.bi_direction+ elem.targetlanguage.data.lang_name;
            });
          }

        this.data = response.data;
        }

    }, (error) => {
    });
  }


  back(){
    this.router.navigate(['/dashboard']);
  }

}
