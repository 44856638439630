<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" [routerLink]="'/dashboard'">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>

    <span class="title-text h1">
        {{ref}}

      <span class="prfInfo"><b>Time Elapsed:</b> 3 Hours <span class="prfo"><b>Due in:</b> <span class="hText hRed"> 2.7
            Hours</span></span> </span>

    </span>
  </div>

  <div class="white-box">
    <div class="tHeader">
      <h4> Project Summary </h4>
      <div fxFlex class="text-right">
        Rush Project: <b><span *ngIf="rush_project==1">Yes</span><span *ngIf="rush_project==0">No</span></b>
      </div>
    </div>

    <div class="boxBody">

      <div class="boxBodyInn">

        <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-1" fxFlex="20%">
            <p class="dLabel">Source Language</p>
            <p class="dValue">{{source_lang}}</p>
          </div>
          <div class="item item-2" fxFlex="20%">
            <p class="dLabel">Target Language</p>
            <p class="dValue">{{target_lang}}</p>
          </div>
          <div class="item item-3" fxFlex="20%">
            <p class="dLabel">Specialization</p>
            <p class="dValue">{{specialization_name}}</p>
          </div>
          <div class="item item-4" fxFlex="20%">
              <p class="dLabel" *ngIf="duration">Estimated Time</p>
              <p class="dValue" *ngIf="duration">{{duration}}
                  <span *ngIf="duration_unit==1">Minutes</span>
                  <span *ngIf="duration_unit==2">Hours</span>
                  <span *ngIf="duration_unit==3">Days</span>
                </p>
            </div>

        </div>


        <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <div class="item item-5" fxFlex="20%">
            <p class="dLabel">Deadline</p>
            <p class="dValue">{{due_date}}</p>
          </div>
          <div class="item item-1" fxFlex="20%">
            <p class="dLabel">Scheduled Update</p>
            <p class="dValue" *ngIf="update_status_interval">{{update_status_interval}} </p>
          </div>
          <div class="item item-2" fxFlex="20%">
            <p class="dLabel">Time Elapsed</p>
            <p class="dValue"></p>
          </div>
          <div class="item item-3" fxFlex="20%">
            <p class="dLabel">Updates required</p>
            <p class="dValue"></p>
          </div>

        </div>



      </div>

    </div>

  </div>
  <form fxLayout="column"  name="form" [formGroup]="form" (ngSubmit)="submit();">
  <div class="white-box">
    <div class="tHeader">
      <h4> Updates Version :{{version}} </h4>
    </div>

    <div class="boxBody">

      <div class="boxBodyInn">
        <div class="fileUploadWraper">
          <mat-label>Upload update v{{version}} file(s)  </mat-label> 
          <button mat-raised-button color="accent" type="button" matTooltip="Upload Document" (click)="onFileUpload()">
           Upload
          </button>
          <input type="file" id="docUpload" name="docUpload" style="display:none;" multiple/>
          <div fxLayout="row" fxLayoutAlign="center center">
              <mat-error *ngIf="fileError !== ''">{{fileError}}</mat-error>
              <span style="color: green;">{{fileSuccess}}</span>
          </div>
    </div>
    <div class="box-blank" *ngIf="uploaded_files_array.length>0">
      <p *ngFor="let res of uploaded_files_array">
          <span *ngFor="let res1 of res">
                  <p>{{res1.name}}</p>
          </span>
       
        </p>
   </div>
        <p>
          <mat-form-field class="d-full-width">
            <textarea matInput placeholder="Leave a comment"   formControlName="comment" ></textarea>
            <mat-hint>Translation details, Any problems with the translation. Anything important we should know?
            </mat-hint>
            <mat-error *ngIf="form.get('comment').hasError('required')">
              Comment is required!
          </mat-error>
          </mat-form-field>
        </p>
       <p>        
         <mat-form-field fxFlex="10"> 
            <label>Completion %</label>
          <input matInput   type="number" formControlName="percentage_completed">
          <mat-error *ngIf="form.get('percentage_completed').hasError('required')">
             completion percentage is required!
        </mat-error>
        </mat-form-field>
      </p>
       
      </div>
    
      <div class="actWraper">
        <p>
          <button mat-raised-button color="accent" [disabled]="(!form.valid) || file_id_array.length==0">Submit</button>
        </p>
      </div>




    </div>


  </div>
  
</form>
  <div class="white-box">
    <div class="tHeader">
      <h4> Updates History </h4>
    </div>

    <div class="boxBody">

      <div class="boxBodyInn">


        <mat-table class="products-table" #table [dataSource]="dataSource" matSort  fusePerfectScrollbar>
    
          <ng-container matColumnDef="version">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="28">Version</mat-header-cell>
              <mat-cell *matCellDef="let product"  fxFlex="28" >
              
              

                  <div fxLayout="column" class="pt-16">
               <p class="text-truncate" *ngIf="product.version">Updated Version: {{product.version}}</p> 
                  </div>
              </mat-cell>
              
          </ng-container> 

          <ng-container matColumnDef="time_duration">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="40">Time Duration</mat-header-cell>
              <mat-cell *matCellDef="let product"  fxHide fxShow.gt-xs fxFlex="40">
            
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="comment">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Comment</mat-header-cell>
              <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">


                  <span class="ml-8" >
                      {{product.comment }} 
                   
                </span>

              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="files">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="32">Files</mat-header-cell>
                <mat-cell *matCellDef="let product"  fxHide fxShow.gt-sm fxFlex="32">
  
  
                    <p class="price text-truncate">
                      <span *ngFor="let res of product.project_assignment_update">
                          <p *ngFor="let res_file of res.project_file.file">{{res_file.name}} </p>
                          </span>
                  </p>
                </mat-cell>
            </ng-container>



          <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

          <mat-row *matRowDef="let product; columns: displayedColumns;" 
          class="product">
          </mat-row>

      </mat-table>

      </div>

    </div>





  </div>

  <div class="white-box2">
    <div class="tHeader">
      <h4> Reference </h4>
    </div>

    <div class="boxBody">

      <div class="boxBodyInn">

        <h3 class="text-center">When to deliver your updates</h3>
        <p class="text-center">We need regular updates on files to ensure that they ar not lost and that deadlines are
          met.</p>
        <table class="simple lan-table">
          <thead>
            <tr>
              <th class="text-left">Due Dates</th>
              <th class="text-left">Updates</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                12 hours or less </td>

              <td class="text-left">
                Every 4 hours with file
              </td>

            </tr>

            <tr>
              <td>
                12 - 24 hours </td>

              <td class="text-left">
                Every 8 hours with file
              </td>

            </tr>

            <tr>
              <td>
                1-2 days </td>

              <td class="text-left">
                Every 12 hours with file
              </td>

            </tr>


            <tr>
              <td>
                2-3 Days </td>

              <td class="text-left">
                Every 24 hours with file
              </td>

            </tr>

            <tr>
              <td>
                3-7 days </td>

              <td class="text-left">
                Every 2 days with file
              </td>

            </tr>

            <tr>
              <td>
                1 week - 2 weeks </td>

              <td class="text-left">
                Every 3 days with file
              </td>

            </tr>

            <tr>
              <td>
                2 week - 1 month </td>

              <td class="text-left">
                Every 4 days with file
              </td>

            </tr>

            <tr>
              <td>
                1 month or more </td>

              <td class="text-left">
                Every week with file
              </td>

            </tr>




          </tbody>
        </table>

        <div class="box-blank amber-50">
            <h3 class="text-center">Always turn in your update based on this chart. This applied to EVERY project.</h3>
                      </div>

      </div>

    </div>





  </div>

</div>