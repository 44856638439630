import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'app/main/services/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnInit {
  notification_count:number=0;
  notification_response:any=[];
  constructor( private projectService: ProjectService,private toastr: ToastrService,) { }

  ngOnInit() {
    this.getAllNotifications();
  }

  getAllNotifications(){
    this.projectService.allNotification()
        .subscribe((response:any) => {
          response.data.forEach(element => {
            if(element.type== "App\\Notifications\\ProjectAssignmentNotification"){
              this.notification_count=this.notification_count+1;
              let data ={
                heading : element.data.data,
                content : element.data.content,
              }
          
                    this.notification_response.push(data);
                
            }
        });
        
      }, (error) => {
          this.toastr.error(error);
          
      });

}

}
