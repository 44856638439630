<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>

    <span class="title-text h1">
      Invoice # DAY001
    </span>
  </div>



  <div class="white-box">

    <div class="invHeader">

      <div class="ribbon ribbon-top-left"><span>Paid</span></div>

      <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">

        <div class="item item-1" fxFlex="auto">
          <div class="logoWrap">
            <img class="invLogoImg" src="assets/images/logos/vlogo.PNG" />
          </div>
        </div>

        <div class="item item-2" fxFlex="auto">
          <div class="invAdd">
            <h2>Invoice</h2>
            <p> Dr Winston Choi,</p>
            <p>4 Acres Rd. Kellyville New South Wales 2155, <br /> Australia, +61 433 281 004 </p>
          </div>
        </div>

      </div>
    </div>










    <div class="boxBody">

      <div class="boxBodyInn">


        <div class="invBilToInfo">

          <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
            fxLayoutGap.xs="0">

            <div class="item item-1" fxFlex="50%">
              <div class="bto">
                <p class="mb-0 mt-0"><b>Bill to</b></p>
                <p class="mb-0 mt-0"> Day Translations, Inc. </p>
                <p class="mt-0"> 415 Madison Avenue, 15th Floor, New York City NY 10017, United States
                </p>
                <p class="mb-0"><b>Phone:</b> 1-800-969-6853</p>
                <p class="mt-0"><b>Fax:</b> 1-800-856-2759 </p>


              </div>
            </div>

            <div class="item item-2" fxFlex="50%">
              <div class="invInfo">

                <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                  fxLayoutGap.xs="0">
                  <div class="item item-2 text-right" fxFlex="50%">
                    <p>Invoice Number:</p>
                  </div>

                  <div class="item item-2" fxFlex="50%">
                    <p><b> DAY001</b></p>
                  </div>

                </div>


                <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                  fxLayoutGap.xs="0">
                  <div class="item item-2 text-right" fxFlex="50%">
                    <p>Invoice Date:</p>
                  </div>

                  <div class="item item-2" fxFlex="50%">
                    <p> <b> 19 Apr 2019</b></p>
                  </div>

                </div>

                <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                  fxLayoutGap.xs="0">
                  <div class="item item-2 text-right" fxFlex="50%">
                    <p>Terms:</p>
                  </div>

                  <div class="item item-2" fxFlex="50%">
                    <p> <b> NET 15</b></p>
                  </div>

                </div>


                <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                  fxLayoutGap.xs="0">
                  <div class="item item-2 text-right" fxFlex="50%">
                    <p>Due Date:</p>
                  </div>

                  <div class="item item-2" fxFlex="50%">
                    <p> <b> 4 May 2019</b></p>
                  </div>

                </div>


                <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px"
                  fxLayoutGap.xs="0">
                  <div class="item item-2 text-right" fxFlex="50%">
                    <p>Purchase order#</p>
                  </div>

                  <div class="item item-2" fxFlex="50%">
                    <p><b>proj12345</b></p>
                  </div>

                </div>





              </div>

            </div>
          </div>





          <div class="content">
            <table class="simple invoice-table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Volume</th>
                  <th class="text-right">Rate</th>
                  <th class="text-right">Adjustments</th>
                  <th class="text-right">Amount (USD)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="title">
                      Spanish < English Translation Transcription & Translation </div> </td> <td>
                        352 words
                  </td>
                  <td class="text-right">
                    0.08USD / Word
                  </td>
                  <td class="text-right">
                    -
                  </td>
                  <td class="text-right">
                    $28.16 USD
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="simple invoice-table-footer">
              <tbody>
                <tr class="subtotal">
                  <td>SUBTOTAL</td>
                  <td>$28.16 USD</td>
                </tr>
                <tr class="tax">
                  <td>Total</td>
                  <td>$28.16 USD</td>
                </tr>
                <tr class="discount">
                  <td>Payment Made</td>
                  <td>$28.16 USD</td>
                </tr>
                <tr class="total">
                  <td>Balance Due (USD)</td>
                  <td>$00.00 USD</td>
                </tr>
              </tbody>
            </table>
          </div>









          <div class="box-blank">
            <p><b>Notes:</b> </p>
            <p> Skrill ID : winschoi@hotmail.com <br />
              Paypal ID : winschoi@hotmail.com</p>

            <p> <b>Bank Transfer</b></p>
            <p> Name of account holder : Dong Won Choi<br />
              Bank name : Westpac<br />
              Branch address : Shop100 Stockland Mall Shopping Centre Baulkham Hills NSW 2153 Australia<br />
              Account number : 732170 667637<br />
              SWIFT code : WPACAU2S</p>

          </div>









        </div>






      </div>





    </div>

  </div>