import { Injectable } from '@angular/core';
import { Observable, Subject, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SharedService } from '../shared.service';
@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  BaseUrl: any;

  constructor(public http: HttpClient) { 
    this.BaseUrl = environment.APIEndpoint;
  }

  getProject_detail(id: any){
    let headers = SharedService.getPutHeader();

    return this.http.get(this.BaseUrl + '/freelancer/assignment_detail/'+ id, { headers });
  }
  downloadZip(data: any):Observable<any> {
    let headers = SharedService.getPutHeader();
    
    return this.http.post(this.BaseUrl + '/projectfilezip', data , { headers });
  }

  uploadFile(data):Observable<any> {
    let headers = SharedService.getFileHeader();
    return this.http.post(this.BaseUrl + '/vendors/uploadfile', data, { headers: headers });
  }

  projectAssignmentUpdate(data):Observable<any> {
    let headers = SharedService.getFileHeader();
    return this.http.post(this.BaseUrl + '/project_assignment/update', data, { headers: headers });
  }

  projectAssignmentFinish(data):Observable<any> {
    let headers = SharedService.getFileHeader();
    return this.http.post(this.BaseUrl + '/project_assignment/finish', data, { headers: headers });
  }

  getProject_Assignment_Update(project_id: any){
    let headers = SharedService.getPutHeader();

    return this.http.get(this.BaseUrl + '/project_assignment/update/'+ project_id, { headers });
  }

  
updateComment(id: any, data: any): Observable<any> {
  let headers = SharedService.getPutHeader();

  return this.http.post(this.BaseUrl + '/project/' + id + '/comments' , data, { headers: headers });
}


getProject_comment(project_id: any){
  let headers = SharedService.getPutHeader();

  return this.http.get(this.BaseUrl + '/freelancer/project_comments/'+ project_id, { headers });
}

unreadNotification(): Observable<any> {
  let headers = SharedService.getPutHeader();
  return this.http.get(this.BaseUrl + '/me/notification?read_status=unread', { headers });
}

notificationRead(data: any): Observable<any> {
  let headers = SharedService.getPutHeader();
  return this.http.get(this.BaseUrl + '/me/notification/all/read', { headers });
}

allNotification(): Observable<any> {
  let headers = SharedService.getPutHeader();
  return this.http.get(this.BaseUrl + '/me/notification', { headers });
}
}
