import { Component } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

@Component({
    selector   : 'welcome',
    templateUrl: './welcome.component.html',
    styleUrls  : ['./welcome.component.scss']
})
export class WelcomeComponent
{
    /**
     * Constructor
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    )
    {
 // Configure the layout
 this._fuseConfigService.config = {
    layout: {
        navbar   : {
            hidden: true
        },
        toolbar  : {
            hidden: false
        },
        footer   : {
            hidden: false
        },
        sidepanel: {
            hidden: true
        }
    }
};

        
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    }
}
