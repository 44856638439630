import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'app/main/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute} from '@angular/router';
import { DashboardService } from 'app/main/services/dashboard.service';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { SharedService } from 'app/main/shared.service';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {

  source_lang :string= '';
  target_lang:string = '';
  rush_project:number = 0;
  ref:string='';
  reference_number:string='';
  due_date:string='';
  quantity_words:number;
  amount:string='';
  file:string = '';
  file_name:any;
  rate:number;
  project_id:string;
  file_d:string;
  file_url:string;
  duration:number;
  duration_unit:string='';
  update_status_interval:string;
  project_language_assignment_id:number;
  Form: FormGroup;
  show:boolean = true;
  file_id_data:any=[];
  file_name_data:any=[];
  customer_note:string;
  instruction:string;
  status:string;
  comment_data_update:any={};
  commentForm: FormGroup;
  user_id:string;
  user_name:string='';
  specialization_name:string = '';
  comment_Response:any=[];
  constructor(private projectService : ProjectService,private router: Router,private route: ActivatedRoute,
    private toastr: ToastrService,private dashboardService : DashboardService,private _formBuilder: FormBuilder
    ) { }

  ngOnInit() {
    this.user_id=SharedService.get('id');
    this.user_name=SharedService.get('username');
    this.project_language_assignment_id = parseInt(this.route.snapshot.params['id']);
    this.getProject_detail();
    
    this.Form = this._formBuilder.group({
      confidential  : ['',Validators.compose([Validators.required])],
      payment_source_word  : ['',Validators.compose([Validators.required])],
      discrepancy_word_count  : ['',Validators.compose([Validators.required])],
      payment_instructions  : ['',Validators.compose([Validators.required])],
      Category  : ['',Validators.compose([Validators.required])],
   
     
  });
  }

  getProject_detail() {

    this.commentForm = this._formBuilder.group({ 
      message: ['', Validators.compose([Validators.required])],
      message_type: ['3', Validators.compose([Validators.required])]
  
  });
 
    this.projectService.getProject_detail(this.project_language_assignment_id)
    .subscribe((response:any) => {
      if(response) {
        response.data.forEach(element => {
     
          this.quantity_words=element.quantity_words;
          this.amount=element.amount;
          this.rate=element.rate;
          this.duration=element.duration;
          this.duration_unit=element.duration_unit;
          this.status=element.status;
          this.update_status_interval= this.timeConvert(element.update_status_interval) ;
          this.instruction=element.instruction;
          this.due_date=element.due_date;
          element.project_language.forEach(elm => {
          this.source_lang = elm.sourcelanguage.lang_name ;
          this.target_lang=elm.targetlanguage.lang_name;
          this.rush_project = elm.project.rush_project;
          if(elm.project.specilization){
            this.specialization_name=elm.project.specilization.name;
          }
          this.reference_number=elm.project.reference;
          this.ref=elm.project.reference+'-'+this.project_language_assignment_id;
         
          this.customer_note=elm.project.note;
          this.project_id=elm.project_id;
          this.getProject_comment();
          });

          if( element.project_assignment_file.length>0){
            element.project_assignment_file.forEach(file_response => {
              let  obj:any={
                 file:'',
                 file_url:''
               }
             this.file =  file_response.file.name ;
             this.file_url="https://tmsapi.daydevelopers.com" + file_response.file.path;
             obj.file= this.file ;
             obj.file_url= this.file_url ;
             this.file_name_data.push(obj);
             this.file_id_data.push(file_response.file.id);
             });
          }
       
          if(this.file_id_data.length>0){
           // this.DownloadZip();
          }
        });
    }
    }, (error) => {
        console.log(error);
    });
  }


  DownloadZip(){
    let data={
     project_id:'',
     file_ids: []
    }
    data.project_id=this.project_id;
    data.file_ids=this.file_id_data;
    this.projectService.downloadZip(data).subscribe(
         resposne => {
             if (resposne) {
                   this.file_d= resposne.url;
             }else{
              this.toastr.error("Something went wrong!!");
             }
         },
         error => {
        
           this.toastr.error(error);
         }
     );
 }

Accept(){
  let obj:any={
    project_language_assignment_id:this.project_language_assignment_id,
    status:'2',
    freelance_name:this.user_name,
    reference_number:this.reference_number,
    due_date:this.due_date

  }

  this.dashboardService.ProjectAssignmentAccept(obj)
  .subscribe((response) => {
      if(response) {
        this.toastr.success("Project Assignment Accept Successfully");
        this.router.navigate(['/dashboard']);
      }
      else{
        this.toastr.error("Something went wrong");
      }
  }, (error) => {
      this.toastr.error("Someting went wrong");
  });
}

Reject(){
  let obj:any={
    project_language_assignment_id:this.project_language_assignment_id,
    status:'6',
    freelance_name:this.user_name,
    reference_number:this.reference_number,
    due_date:this.due_date
  }
  this.dashboardService.ProjectAssignmentReject(obj)
  .subscribe((response) => {
      if(response) {
        this.toastr.success("Project Assignment Reject Successfully");
        this.router.navigate(['/dashboard']);
      }
      else{
        this.toastr.error("Something went wrong");
      }
  }, (error) => {
      this.toastr.error("Someting went wrong");
  });
}


check_term_conditions(evt:any){
  if(evt.checked && this.Form.value.confidential && this.Form.value.payment_source_word && 
    this.Form.value.discrepancy_word_count && this.Form.value.payment_instructions && this.Form.value.Category  ){
    this.show=false;
  }
  else{
    this.show=true;
  }
}

         // calulate time from minute

         timeConvert(n) {

          if(n<0){
            n=-1*n;
          }
          var num = n;
          var hours = (num / 60);
          var rhours = Math.floor(hours);
          var days = (hours / 24);
          var rdays = Math.floor(days);
          var minutes = (hours - rhours) * 60;
          var rminutes = Math.round(minutes);
         
          return  rhours + " hour(s) and " + rminutes + " minute(s)";
          }

          
submitComment(){

  this.comment_data_update.message_type=this.commentForm.value.message_type;
  this.comment_data_update.message=this.commentForm.value.message;
  this.comment_data_update.from_user_id=this.user_id;
 
  this.projectService.updateComment(this.project_id,this.comment_data_update)
    .subscribe((response) => {
        if(response) {
          
          if(response.data.mail_response){
            this.toastr.success(response.data.mail_response);
          }
          else{
            this.toastr.success("Updated");
          }
          this.commentForm = this._formBuilder.group({ 
            message: ['', Validators.compose([Validators.required])],
            message_type: ['3', Validators.compose([Validators.required])]
        
        });

        this.commentForm.patchValue({
          
          message: '',
          message_type: '3'
      });
         
          this.getProject_comment();
     
          
         
        
        }
    }, (error) => {
        this.toastr.error(error);
        
    });


}


getProject_comment() {

  this.projectService.getProject_comment(this.project_id)
  .subscribe((response:any) => {

    if(response) {
      
      this.comment_Response=response.data;
      
    }else{
      this.toastr.error("Somthing went wrong");
    }
  }, (error) => {
      console.log(error);
  });
}



}
