<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
    <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
      <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" [routerLink]="'/dashboard'">
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
  
      <span class="title-text h1">
          {{ref}}
  
        <span class="prfInfo"><b>Time Elapsed:</b> 3 Hours <span class="prfo"><b>Due in:</b> <span class="hText hGreen"> 2.7
              Hours</span></span> </span>
  
      </span>
    </div>
  
    <div class="white-box">
      <div class="tHeader">
        <h4> Project Summary </h4>
        <div fxFlex class="text-right">
          Rush Project: <b><span *ngIf="rush_project==1">Yes</span><span *ngIf="rush_project==0">No</span></b>
        </div>
      </div>
  
      <div class="boxBody">
  
        <div class="boxBodyInn">
  
          <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div class="item item-1" fxFlex="20%">
              <p class="dLabel">Source Language</p>
              <p class="dValue">{{source_lang}}</p>
            </div>
            <div class="item item-2" fxFlex="20%">
              <p class="dLabel">Target Language</p>
              <p class="dValue">{{target_lang}}</p>
            </div>
            <div class="item item-3" fxFlex="20%">
              <p class="dLabel">Specialization</p>
              <p class="dValue">{{specialization_name}}</p>
            </div>
            <div class="item item-4" fxFlex="20%">
                <p class="dLabel" *ngIf="duration">Estimated Time</p>
                <p class="dValue" *ngIf="duration">{{duration}}
                    <span *ngIf="duration_unit==1">Minutes</span>
                    <span *ngIf="duration_unit==2">Hours</span>
                    <span *ngIf="duration_unit==3">Days</span>
                  </p>
              </div>
          </div>
  
  
          <div class="container" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div class="item item-5" fxFlex="20%">
              <p class="dLabel">Deadline</p>
              <p class="dValue">{{due_date}}</p>
            </div>
            <div class="item item-1" fxFlex="20%">
              <p class="dLabel">Scheduled Update</p>
              <p class="dValue" *ngIf="update_status_interval">{{update_status_interval}} </p>
            </div>
            <div class="item item-2" fxFlex="20%">
              <p class="dLabel">Time Elapsed</p>
              <!-- <p class="dValue">3 Hours</p> -->
            </div>
            <div class="item item-3" fxFlex="20%">
              <p class="dLabel">Updates required</p>
              <!-- <p class="dValue">Every 4 Hours</p> -->
            </div>
  
          </div>
  
  
  
        </div>
  
  
  
  
  
      </div>
  
    </div>
    <form fxLayout="column"  name="form" [formGroup]="form" (ngSubmit)="submit();">
    <div class="white-box">
      <div class="tHeader">
        <h4>Finished Translation Upload </h4>
      </div>
  
      <div class="boxBody">
  
        <div class="boxBodyInn">
  
  
          <div class="fileUploadWraper">
            <mat-label>Upload update v{{version}} file(s)  </mat-label> 
            <button mat-raised-button color="accent" type="button" matTooltip="Upload Document" (click)="onFileUpload()">
             Upload
            </button>
            <input type="file" id="docUpload" name="docUpload" style="display:none;" multiple/>
            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-error *ngIf="fileError !== ''">{{fileError}}</mat-error>
                <span style="color: green;">{{fileSuccess}}</span>
            </div>
      </div>
      <div class="box-blank" *ngIf="uploaded_files_array.length>0">
        <p *ngFor="let res of uploaded_files_array">
            <span *ngFor="let res1 of res">
                    <p>{{res1.name}}</p>
            </span>
         
          </p>
     </div>
          <p>
            <mat-form-field class="d-full-width">
              <textarea matInput placeholder="Leave a comment"  formControlName="comment"></textarea>
              <mat-hint>Translation details, Any problems with the translation. Anything important we should know?
              </mat-hint>
              <mat-error *ngIf="form.get('comment').hasError('required')">
                Comment is required!
            </mat-error>
            </mat-form-field>
          </p>
  
        </div>
  
        <div class="actWraper">
          <p>
            <button mat-raised-button color="accent" class="mr-12" [disabled]="(!form.valid) || file_id_array.length==0">Submit</button>

            <button mat-raised-button color="primary" [disabled]="true">Generate Invoice</button>
          </p>
        </div>
      </div>
    </div>
</form>
    <div class="white-box">
        <div class="tHeader">
          <h2> Questions or comment? </h2>
        </div>
        <div class="boxBody">
    
          <div class="boxBodyInn">
            <div class="commentDiv">
              <div class="quesDiv">
                <p><b>Dr. Winston Choi:</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
                <p>I have some question regarding this project. Can we put somme comment here and you reply. I really need
                  to clarify something.</p>
              </div>
              <div class="ansDiv">
                <div class="divinn">
                  <p><b>Ms Basama (DayPM):</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
                  <p>Yes sure please share your question, I will glad to answer all of your questions.</p>
                </div>
              </div>
    
              <div class="quesDiv">
                <p><b>Dr. Winston Choi:</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
                <p>Thats really great, I am getting reply promptly.</p>
              </div>
              <div class="ansDiv">
                <div class="divinn">
                  <p><b>Ms Seltiper (DayPM):</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
                  <p>You are very welcome!</p>
                </div>
              </div>
    
            </div>
    
            <div class="box-blank amber-50">
    
    
    
    
              <p class="bLabel">We will reply immediately</p>
    
              <p>We are here 24/7 for any questions you may have</p>
    
              <p>
                <mat-form-field class="d-full-width">
                  <textarea matInput placeholder="Leave a comment" ></textarea>
                  <mat-hint>Note: if you have questions before accepting, please enter them here and click I have questions
                    to not accept for now.</mat-hint>
                </mat-form-field>
              </p>
    
              <p> <button mat-raised-button color="primary">I have questions</button></p>
    
            </div>
    
          </div>
        </div>
      </div>
  
  </div>