<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <div class="pTitle">
                <!-- <h1>{{user_group_name}}</h1> -->
            </div>

            

        </div>

        <!-- <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
        <div class="toolbar search-wrapper">
            <div class="search" fxlayout="row" fxlayoutalign="start center"><mat-icon class="secondary-text mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">search</mat-icon><input fxflex="" placeholder="Search for anything" class="ng-pristine ng-valid ng-touched"></div>
        </div>
        </div> -->

        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                   
                <!-- <button mat-button class="user-button belI">
                        <i class="material-icons">chat_bubble_outline</i>
                 </button> -->


                <button mat-button class="user-button belI" [matMenuTriggerFor]="helpWrap">
                        <i class="material-icons">help_outlin</i>
                 </button>
                 <mat-menu #helpWrap="matMenu" [overlapTrigger]="false">
                    <!-- <button mat-menu-item routerLink="/pms/help" title="Help on This Page?">
                        Help on This Page?
                    </button>
                    <button mat-menu-item routerLink="/pms/kb" title=" Knowledge Base">
                        Knowledge Base
                    </button> -->
                    <button mat-menu-item routerLink="/support" title=" Report Problem">
                        Report Problem
                    </button>
                </mat-menu>

            <button mat-button [matMenuTriggerFor]="ntfWrap" class="user-button belI">
                <i [matBadge]="notification_response.length" matBadgeColor="warn" class="material-icons">notifications </i>

            </button>

            <mat-menu #ntfWrap="matMenu" [overlapTrigger]="false" class="ntfMenu">

                <div class="notWraper">

                    <div class="ntHeader">
                        <h3> <span [matBadge]="notification_response.length" matBadgeColor="accent">Recent Notifications</span>
                            <!-- <span class="tlink ml-64" (click)="notification_read()">Mark as read</span> -->
                            </h3>
                    </div>

                    <div class="allMsg" *ngIf="notification_response.length>0">
                        <div class="msgRow munRead cBlue" *ngFor="let res of notification_response">
                            <div fxFlex="10">
                                <p class="mTypeIcon"> <i class="material-icons">info</i></p>
                            </div>
                            <div fxFlex="75">
                                <p><b>{{res.heading}}</b></p>
                                <p>{{res.content}}</p>
                            </div>

                        </div>

                    </div>

                    <div class="ftr">
                        <p class="tlink" routerLink="/projects/notifications">All Notifications</p>

                    </div>


                </div>






            </mat-menu>


            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row wrap" fxLayoutAlign="left">
                   <div fxFlex>
                    <img class="avatar mr-0" [src]="profile_pic"
                        onerror="this.src='assets/images/avatars/profile.jpg'">
                    </div>
                    <div fxFlex>
                        <div class="pl-8 pt-4">
                    <div class="username text-left" fxHide fxShow.gt-sm><span matBadgePosition="above after" matBadge="." matBadgeColor="available">{{user_name}}</span></div>
                    <div class="logTime text-left">Jun 16 07:15 GMT (GMT+0)</div>
                </div>
                    </div>
                </div>
                
            </button>

            <mat-menu #uStatus="matMenu">
                <button mat-menu-item><mat-icon class="sAvailable">fiber_manual_record</mat-icon> Available</button>
                <button mat-menu-item><mat-icon class="sIdle">fiber_manual_record</mat-icon> Idle</button>
                <button mat-menu-item><mat-icon class="sBusy">fiber_manual_record</mat-icon> Busy</button>
            </mat-menu>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item (click)="profile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="uStatus">
                    <mat-icon class="sAvailable">fiber_manual_record</mat-icon>
                    <span>Change Status</span>
                </button>
          

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <div class="toolbar-separator"></div>

            <!-- <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button> -->

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <!-- <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button> -->

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <!-- <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->

        </div>

    </div>

</mat-toolbar>