<div id="portal-selector" class="page-layout simple fullwidth">

    <!-- HEADER -->
    <div class="header" fxLayout="row" fxLayoutAlign="start start">

        <div class="hero-text" fxLayout="column" fxLayoutAlign="center center" fxFlex>

            <div class="h1">Please Select Portal!</div>
            <div class="h3">
                Based on your roles you have following portal access. You can also select specific portal by default to
                open.
            </div>

        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <mat-grid-list cols="4">
            <mat-grid-tile> <div class="card">
                <div class="card-body">
                        <button class="btnPlain" [routerLink]="'/admin-dashboard'">
                    <div class="cinn">
                    <div class="card-icon">
                        <span>
                            <mat-icon>supervised_user_circle</mat-icon>
                        </span>
                    </div>

                    <div class="titleWRaper">
                        <h2>Admin Login <i class="iconArrow material-icons"> arrow_forward </i> </h2>

                    </div>

                    <hr />

                    <div class="info">
                        <p>Manage portal users, review and approve freelancers and more</p>
                    </div>
                </div>
            </button>

<div class="chkbox"><mat-checkbox>Set this as default.</mat-checkbox></div>

                </div>
            </div></mat-grid-tile>
            <mat-grid-tile>  <div class="card">
                <div class="card-body">
                        <div class="cinn">
                    <div class="card-icon">
                        <span>
                            <mat-icon>group</mat-icon>
                        </span>
                    </div>

                    <div class="titleWRaper">
                        <h2>HRD Login <i class="iconArrow material-icons"> arrow_forward </i></h2>
                    </div>
                    <hr />
                    <div class="info" fxLayout="column">
                        <p>Manage freelancers, new resouces, evaluation and more</p>
                    </div> </div>
                    <div class="chkbox"><mat-checkbox>Set this as default.</mat-checkbox></div>


                </div>
            </div>
</mat-grid-tile>
            <mat-grid-tile>   <div class="card">
                <div class="card-body">
                        <div class="cinn">
                    <div class="card-icon">
                        <span>
                            <mat-icon>group_work </mat-icon>
                        </span>
                    </div>

                    <div class="titleWRaper">
                        <h2>PMS Login <i class="iconArrow material-icons"> arrow_forward </i></h2>
                    </div>
                    <hr />
                    <div class="info" fxLayout="column">
                        <p>Manage project, estimation, assignation, delivery and more</p>
                    </div>
               
                </div>
                    <div class="chkbox"><mat-checkbox>Set this as default.</mat-checkbox></div>
                </div>
            </div></mat-grid-tile>
            <mat-grid-tile>
                <div class="card">
                    <div class="card-body">
                            <div class="cinn">
                        <div class="card-icon">
                            <span>
                                <mat-icon>monetization_on </mat-icon>
                            </span>
                        </div>
    
                        <div class="titleWRaper">
                            <h2>Finance Login <i class="iconArrow material-icons"> arrow_forward </i></h2>
                        </div>
                        <hr />
                        <div class="info" fxLayout="column">
                            <p>Fincancial analytics, revew project lifecycle and more</p>
                        </div>
                            </div>
                        <div class="chkbox"><mat-checkbox>Set this as default.</mat-checkbox></div>
                    </div>
                </div></mat-grid-tile>


        </mat-grid-list>


    </div>

</div>